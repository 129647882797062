export default {
  fields: {
    code: 'Code',
    startTime: 'Start Time',
    endTime: 'End Time',
    airline: 'Airline',
    minimumPassenger: 'Minimum Passenger',
    priority: 'Priority',
    description: 'Description',
    flightStartTime: 'Flight Start Time',
    flightEndTime: 'Flight End Time',
  },
  placeholder: {
    code: 'Enter Code',
    startTime: 'Select Start Time',
    endTime: 'Select End Time',
    airline: 'Select Airline',
    minimumPassenger: 'Enter Minimum Passenger',
    priority: 'Low to high: 1 - 10',
    description: 'Enter description',
    flightStartTime: 'Select Flight Start Time',
    flightEndTime: 'Select Flight End Time',
  },
  create: {
    title: 'Create',
  },
  edit: {
    title: 'Edit',
  },
  delete: {
    confirm: 'Are you sure delete this promotion?',
  },
}
