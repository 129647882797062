export default {
  title: 'Danh sách công nợ',
  searchPlaceholder: 'Tìm kiếm',
  customerCodePlaceholder: 'Mã đại lý/KH',
  openingBalance: 'Dư đầu kỳ',
  closingBalance: 'Dư cuối kỳ',
  tradeCreditors: 'Tổng phải trả nhà cung cấp',
  receivables: 'Tổng phải thu',
  receivablesTitle: 'Phát sinh trong kỳ',
  paid: 'Đã thanh toán',
  unpaidCommission: 'Hoa hồng chưa thanh toán',
  collectionFee: 'Thu phí',
  type: 'Loại',
  update: 'Cập nhật',
  bookClosing: 'Khoá sổ',
  agCode: 'Mã đại lý/khách hàng',
  agName: 'Tên đại lý/khách hàng',
  action: 'Thao tác',
  checkbox: '',
  view: 'Hiển thị',
  recordPerPage: 'bản ghi mỗi trang',
  save: 'Lưu',
  date: 'Ngày',
  sendEmail: 'Gửi email',
  selectFileTypePlaceholder: 'Lựa chọn loại file',
  fileType: 'Loại file',
  chooseFileToUpload: 'Chọn file tải lên',
  downloadSampleFile: 'Tải file mẫu',
  sampleFile: 'File mẫu',
  reservationCode: 'Mã đặt chỗ',
  no: 'STT',
  airline: 'Hãng hàng không',
  source: 'Hệ thống',
  bookingCode: 'Mã đặt vé',
  paxName: 'Tên Hành Khách',
  paxType: 'Loại hành khách',
  ticketNumber: 'Số vé',
  ticketType: 'Loại vé',
  flightType: 'Loại hành trình',
  trips: 'Hành trình',
  flightDates: 'Ngày bay',
  bookingClass: 'Hạng đặt chỗ',
  fareBasisCode: 'Loại giá vé',
  bookingDate: 'Ngày đặt',
  issueDate: 'Ngày thanh toán',
  booker: 'Người đặt',
  agency: 'Đại lý',
  signBook: 'Tài khoản đăng ký',
  currency: 'Loại tiền',
  priceTicket: 'Giá vé',
  vat: 'Thuế',
  tax: 'Thuế phí',
  fee: 'Phí',
  feeService: 'Phí dịch vụ',
  commissionPrepaid: 'Hoa hồng đã trả',
  totalPayment: 'Tổng tiền',
  accountsPayable: 'Công nợ',
  created: 'Ngày tạo',
  updated: 'Ngày cập nhật',

  detail: {
    information: 'Thông tin',
    transaction: 'Giao dịch',

    columns: {
      // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
      no: 'STT',
      created: 'Thời gian tạo',
      updated: 'Thời gian sửa đổi',
      airline: 'Hãng hàng không',
      source: 'Hệ thống đặt vé',
      bookingCode: 'Mã đặt chỗ',
      paxName: 'Tên hành khách',
      paxId: 'Mã số hành khách',
      paxType: 'Loại hành khách',
      ticketNumber: 'Số vé',
      paymentCode: 'Mã thanh toán',
      ticketType: 'Loại vé',
      flightType: 'Loại hành trình',
      trips: 'Hành trình',
      flightDates: 'Ngày bay',
      bookingClass: 'Hạng đặt chỗ',
      fareBasisCode: 'Loại giá vé',
      booker: 'Người đặt vé',
      issuer: 'Người xuất vé',
      issueDate: 'Ngày thanh toán',
      bookingDate: 'Ngày đặt',
      agency: 'Đại lý',
      signBook: 'Tài khoản đăng ký',
      currency: 'Loại tiền',
      priceTicket: 'Giá vé net',
      vat: 'Vat',
      otherTax: 'Thuế phí khác',
      tax: 'Phí thu hộ',
      fee: 'Phí',
      feeIn: 'Phí xuất vé F1',
      feeOut: 'Phí xuất vé F2',
      feeService: 'Phí dịch vụ',
      commissionPrepaid: 'Hoa hồng đã trả',
      commissionPrepaidIn: 'Hoa hồng đã được trả',
      commissionPrepaidOut: 'Hoa hồng đã chi',
      commission: 'Hoa hồng hãng',
      unpaidCommission: 'Hoa hồng trả sau',
      totalPayment: 'Tổng tiền',
      tradeCreditors: 'Tổng phải trả',
      receivables: 'Tổng phải thu',
      accountsPayable: 'Công nợ',
      profit: 'Lợi nhuận',

      // for CHOOSE_TICKET_TABLE_COLUMNS
      checkbox: '',
      ticketNo: 'Số vé',
      invoiceAirline: 'Hãng',
      invoiceIssueDate: 'Ngày đặt vé',
      unitPrice: 'Đơn giá',
      invoiceVAT: 'Tiền thuế',
      invoiceCollectionFee: 'Phí thu hộ',
      invoiceTotalPayment: 'Tổng tiền thanh toán',
      customer: 'Đại lý/ Khách hàng',
      refundDate: 'Ngày hoàn',
      actions: 'Thao tác',
    },
  },
  f2: {
    title: 'Công nợ tổng hợp F2',
  },
}
