export default {
  title: 'Accounts payable',
  searchPlaceholder: 'Search',
  customerCodePlaceholder: 'Agency/Customer Code',
  openingBalance: 'Opening Balance',
  closingBalance: 'Closing Balance',
  tradeCreditors: 'Trade creditors',
  receivables: 'Receivables',
  receivablesTitle: 'Receivables',
  paid: 'Paid',
  unpaidCommission: 'Unpaid Commission',
  collectionFee: 'Collection Fee',
  type: 'Type',
  update: 'Update',
  bookClosing: 'Book Closing',
  agCode: 'Agency/Customer Code',
  agName: 'Agency/Customer Name',
  action: 'Action',
  checkbox: '',
  view: 'View',
  recordPerPage: 'records per page',
  save: 'Save',
  date: 'Date',
  sendEmail: 'Send email',
  selectFileTypePlaceholder: 'Select file type',
  fileType: 'File type',
  chooseFileToUpload: 'Choose file to upload',
  downloadSampleFile: 'Download sample file',
  sampleFile: 'Sample file',
  reservationCode: 'Reservation code',
  no: 'No',
  airline: 'airline',
  source: 'source',
  bookingCode: 'booking code',
  paxName: 'paxName',
  paxType: 'paxType',
  ticketNumber: 'ticket number',
  ticketType: 'ticket type',
  flightType: 'flight type',
  trips: 'trips',
  flightDates: 'Flight Dates',
  bookingClass: 'booking class',
  fareBasisCode: 'fare basis code',
  bookingDate: 'booking date',
  issueDate: 'issue date',
  booker: 'booker',
  agency: 'agency',
  signBook: 'sign book',
  currency: 'currency',
  priceTicket: 'price ticket',
  vat: 'vat',
  tax: 'tax',
  fee: 'fee',
  feeService: 'fee service',
  commissionPrepaid: 'commission prepaid',
  totalPayment: 'totalPayment',
  accountsPayable: 'Accounts payable',
  created: 'created',
  updated: 'updated',

  detail: {
    information: 'Information',
    transaction: 'Transaction',

    columns: {
      // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
      no: 'No.',
      created: 'Created',
      updated: 'Updated',
      airline: 'Airline',
      source: 'Source',
      bookingCode: 'Booking code',
      paxName: 'Pax name',
      paxId: 'Pax ID',
      paxType: 'Pax type',
      ticketNumber: 'Ticket number',
      paymentCode: 'Payment code',
      ticketType: 'Ticket type',
      flightType: 'Flight type',
      trips: 'Trips',
      flightDates: 'Flight Dates',
      bookingClass: 'Booking class',
      fareBasisCode: 'Fare basis code',
      booker: 'Booker',
      issuer: 'Issuer',
      issueDate: 'Issue date',
      bookingDate: 'Book date',
      agency: 'Agency',
      signBook: 'Sign book',
      currency: 'Currency',
      priceTicket: 'Price ticket',
      vat: 'Vat',
      otherTax: 'Other tax',
      tax: 'Collection fee',
      fee: 'Fee',
      feeIn: 'Fee in',
      feeOut: 'Fee out',
      feeService: 'Service fee',
      commissionPrepaid: 'Prepaid commision',
      commissionPrepaidIn: 'Commission Prepaid In',
      commissionPrepaidOut: 'Commission Prepaid Out',
      unpaidCommission: 'Unpaid commision',
      commission: 'Commission',
      totalPayment: 'Total payment',
      tradeCreditors: 'Accrued debt',
      receivables: 'Accrue credit',
      accountsPayable: 'Accounts payable',
      profit: 'Profit',

      // for CHOOSE_TICKET_TABLE_COLUMNS
      checkbox: '',
      ticketNo: 'Ticket number',
      invoiceAirline: 'Airline',
      invoiceIssueDate: 'Issue date',
      unitPrice: 'Unit price',
      invoiceVAT: 'VAT',
      invoiceCollectionFee: 'Collection fee',
      invoiceTotalPayment: 'Total payment',
      customer: 'AG/CTV/Customer',
      refundDate: 'Refund date',
      actions: 'Actions',
    },
  },
  f2: {
    title: 'Accounts payable F2',
  },
}
