export default {
  fields: {
    code: 'Mã giảm giá',
    startTime: 'Thời gian áp dụng bắt đầu',
    endTime: 'Thời gian áp dụng kết thúc',
    airline: 'Hãng hàng không',
    minimumPassenger: 'Số lượng hành khách tối thiếu',
    priority: 'Độ ưu tiên',
    description: 'Mô tả',
    flightStartTime: 'Thời gian bay áp dụng bắt đầu',
    flightEndTime: 'Thời gian bay áp dụng kết thúc',
  },
  placeholder: {
    code: 'Điền vào mã giảm giá',
    startTime: 'Chọn thời gian bắt đầu',
    endTime: 'Chọn thời gian kết thúc',
    airline: 'Chọn hãng hàng không',
    minimumPassenger: 'Điền vào số lượng hành khách tối thiếu',
    priority: 'Từ thấp đến cao: 1 - 10',
    description: 'Nhập mô tả',
    flightStartTime: 'Chọn thời gian bắt đầu',
    flightEndTime: 'Chọn thời gian kết thúc',
  },
  create: {
    title: 'Thêm mới khuyến mãi hãng',
  },
  edit: {
    title: 'Chỉnh sửa khuyến mãi hãng',
  },
  delete: {
    confirm: 'Bạn có chắc chắn xoá khuyến mãi?',
  },
}
