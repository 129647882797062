export default {
  title: 'Audit log',
  management: 'Audit Log Management',
  list: 'Audit Log List',
  features: {
    booking: 'Booking',
    reservation: 'Reservation',
    ticket: 'Ticket',
    agency: 'Agency',
    customer: 'Customer',
    employee: 'Employee',
    invoice: 'Invoice',
    topUp: 'Top Up',
    promotion: 'Promotion',
    feeConfig: 'Fee Config',
    feeService: 'Fee Service',
    agencyBankAccount: 'Agency Bank Account',
  },
  columns: {
    checkbox: '',
    referenceId: 'Reference ID',
    refResource: 'Reference Resource',
    modifiedBy: 'Modified by',
    feature: 'Feature',
    action: 'Action',
    oldValue: 'Old value',
    newValue: 'New value',
    timestamp: 'Timestamp',
    created: 'Created',
    updated: 'Updated',
  },
  placeholderAction: 'Please enter a action',
  placeholderRefId: 'Please enter a reference ID',
  placeholderSearch: 'Search text by feature, action, reference resource',
}
