export default {
  addBankAccount: 'Add bank account',
  editBankAccount: 'Edit bank account',
  verifyBankAccount: 'Verify bank account',
  add: 'Add',
  edit: 'Edit',
  cancel: 'Cancel',
  submit: 'Submit',
  resend: 'Resend',
  action: 'Action',
  bankId: 'Bank',
  branch: 'Branch',
  isDefault: 'Default',
  isAuto: 'Auto Payment',
  bankAccountNo: 'Bank Account Number',
  bankAccountName: 'Bank Holder',
  identificationNumber: 'Identification Number',
  phoneNumber: 'Phone Number',
  status: 'Status',
  label: 'Label',
  otp: 'OTP',
  active: 'Active',
  unActive: 'Unactive',
  ph: {
    bankId: 'Choose bank',
    branch: 'Enter branch',
    isDefault: 'Set default',
    isAuto: 'Set auto payment',
    bankAccountNo: 'Enter bank account number',
    bankAccountName: 'Enter bank holder',
    identificationNumber: 'Enter identification number',
    phoneNumber: 'Enter phone number',
    label: 'Enter label',
    otp: 'Enter OTP',
  },
  DELETING: 'Deleting',
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
}
