export default {
  yes: 'Có',
  no: 'Không',
  iamConfirm: 'Tôi đồng ý',
  confirm: 'XÁC NHẬN',
  logout: 'ĐĂNG XUẤT',
  close: 'Đóng',
  edit: 'Chỉnh sửa',
  save: 'Lưu',
  activate: 'Bật',
  deactivate: 'Tắt',
  back: 'Trở lại',
  cancel: 'Huỷ',

  confirmBan: 'Bạn có chắc chắn muốn cấm?',
  confirmUnban: 'Bạn có chắc chắn muốn bỏ cấm?',
  clearForm: 'Bạn có chắc chắn muốn xoá các dữ liệu đã nhập vào?',
  changeStatus: 'Bạn có chắc chắn muốn thay đổi trạng thái ?',
  changeStatusWithText: 'Bạn có chắc chắn muốn thay đổi trạng thái {text}?',
  setAutoBank: 'Chú ý: Sử dụng dịch vụ xác nhận giao dịch tiền tự động sẽ mất phí giao dịch. Mỗi giao dịch sẽ bị tính phí là {fee} vnđ/giao dịch.',
  unsetAutoBank: 'Chú ý: Bạn đang tắt tính năng tự động xác nhận giao dịch',
  confirmDelete: 'Bạn có chắc chắn muốn xoá?',
  confirmDeactive: 'Bạn có chắc chắn muốn tạm khóa/đăng bán?',
  confirmSendEmail: 'Bạn có chắc chắn muốn gửi mail?',
  confirmPayment: 'Bạn có chắc chắn muốn thanh toán?',
  confirmPaymentWithoutDiscount: 'Bạn có chắc chắn muốn thanh toán không áp dụng chiết khấu?',
  confirmExport: 'Bạn có chắc chắn muốn xuất Excel các dữ liệu?',
  confirmExportChoosen: 'Bạn có chắc chắn muốn xuất Excel các dữ liệu đã chọn?',
  downloadRecentlyFile: 'Tải xuống tệp đã nhập gần đây?',
  downloadSampleFile: 'Tải xuống tệp mẫu?',
  confirmLogout: 'Bạn có chắc chắn muốn đăng xuất?',
  confirmModify: 'Bạn có chắc chắn muốn thay đổi?',
  confirmAddSeat: 'Bạn có chắc chắn muốn thêm ghế?',
  refuseToSign: 'Bạn có chắc chắn muốn từ chối ký?',
  redirect: 'Bạn có muốn chuyển sang trang tiếp theo không?',
  confirmReport: 'Báo cáo vi phạm (phản ánh)',
  confirmCancelEdit: 'Các thông tin bạn đã chỉnh sửa sẽ bị mất. Bạn chắc chắn muốn huỷ chỉnh sửa?',
  confirmCancelInput: 'Các thông tin bạn đã nhập sẽ bị mất. Bạn chắc chắn muốn thoát?',
  sold: 'Bạn có chắc chắn đã bán mã định danh này không? Không thể thao tác sau khi đã bán!',
}
