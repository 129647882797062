export default {
  chooseLanguage: 'Choose your language:',
  welcomeTo: 'Welcome to',
  signInText: 'Please login to your account',
  Username: 'Username',
  Password: 'Password',
  LogIn: 'Log in',
  keepLogin: 'Keep logged in',
  forgotPassword: 'Forgot password?',

  registerEmail: 'Registered email address',
  registerUsername: 'Registered username',
  resetPassword: 'Reset password',
  emailPlaceholder: 'Input email',
  usernamePlaceholder: 'Input username',

  otpCode: 'OTP Code',
  otpCodePlaceholder: 'Enter OTP Code',

  setNewPass: 'Set New Password',
  backToLogin: 'Back to login',
  passwordConfirmText: 'Password Confirmation',
  requiredText_1: 'A confirmation OTP has been sent to your email address ',
  requiredText_1_2: '.',
  requiredText_2: 'Please check email ',
  requiredText_2_2: 'and follow the detail instructions.',
  requiredText_3: 'Did not receive the mail?',
  requiredText_4: 'Resend',

  newPasswordConfirm: 'New password confirmation',
  newPassword: 'New Password',
  passwordConfirm: 'Password confirmation',
  requiredNewPassword_1: 'Strong password required:',
  requiredNewPassword_2: 'At least 8 characters',
  requiredNewPassword_3:
    'Must be included uppercase and lowercase letters, numbers',
  requiredNewPassword_4:
    'Do not contain words that is parts of email (3 consecutive characters).',
  setNewPassword: 'Set New Password',

  continue: 'Continue',
}
