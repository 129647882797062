export default {
  titleRepicient: 'Yêu cầu nạp tiền',
  titleSender: 'Nạp tiền',
  management: 'Quản Lý Nạp Tiền',
  list: 'Danh Sách Nạp tiền',
  paymentStatus: 'Trạng thái thanh toán',
  amount: 'Số tiền',
  paymentAmount: 'Số tiền thanh toán',
  senderId: 'Mã số bên gửi',
  senderName: 'Bên gửi',
  entity: 'Vai trò',
  senderEntity: 'Vai trò bên gửi',
  recipientId: 'Mã số bên nhận',
  recipientEntity: 'Vai trò bên nhận',
  bankAccountName: 'Tên tài khoản ngân hàng',
  bankAccountNo: 'Số tài khoản ngân hàng',
  note: 'Ghi chú',
  evidence: 'Bằng chứng',
  uploadEvidence: 'Tải lên hình ảnh bằng chứng',
  placeholderChooseImage: 'Chọn một hình ảnh hoặc thả nó ở đây...',
  placeholderDropImage: 'Thả hình ảnh ở đây...',
  evidenceUrl: 'Tài liệu bằng chứng nạp tiền',
  bankCode: 'Mã ngân hàng',
  status: {
    pendingPayment: 'Chờ thanh toán',
    done: 'Hoàn thành',
    canceled: 'Đã huỷ',
    confirming: 'Chờ xác nhận',
  },
  startDate: 'Từ ngày',
  endDate: 'Đến ngày',
  bankAcc: 'Tài khoản nhận tiền',
  placeholderSelectDate: 'Chọn ngày',
  placeholderSearch: 'Tìm kiếm theo mã thanh toán, tên người gửi, tên ngân hàng',
  moreDropdownTitle: '',
  tableColumns: {
    checkbox: '',
    paymentCode: 'Mã thanh toán',
    senderInfo: 'Bên nạp tiền',
    created: 'Thời gian tạo',
    updated: 'Thời gian sửa đổi',
    confirmedBy: 'Người xác nhận',
    paymentStatus: 'Trạng thái',
    paymentInfo: 'Thông tin thanh toán',
    actions: 'Hành động',
  },
  currency: 'VND',
  detail: 'Chi Tiết Nạp tiền',
  tabInfo: 'Thông Tin Nạp tiền',
  confirm: 'Xác nhận',
  confirmManual: 'Xác nhận nạp tiền thủ công',
  delete: 'Xoá',
  errFetchData_1: 'Lỗi tìm nạp dữ liệu nạp tiền',
  errFetchData_2: 'Không tìm thấy khoản nạp tiền nào với id của khách hàng này. Kiểm tra',
  errFetchData_3: 'khoản nạp tiền khác.',

  createTopup: 'Tạo lệnh nạp tiền',
  createTopupForChild: 'Tạo lệnh nạp tiền cho đại lý',
  tooltip: {
    allowTopup: 'Chỉ các đại lý đã kí hợp đồng mới được tạo lệnh nạp tiền, vui lòng liên hệ Booker để được hỗ trợ',
  },
  confirmAutoPayment: 'Tự động xuất vé theo đơn hàng',
  at: 'lúc',
  sender: 'Đại lý nạp tiền',
  recipient: 'Đại lý nhận tiền',
  placeholderSender: 'Vui lòng chọn đại lý nạp tiền',
  pleaseTopupMinAmount: 'Vui lòng nạp tiền tối thiểu {minAmount} VNĐ',
  topupInfo: 'Thông tin nạp tiền',
  parentAgencyNotHaveBankAccount: 'Đại lý cấp trên chưa cài đặt tài khoản nhận tiền. Vui lòng liên hệ đại lý cấp trên.',
  createQrSuccess: 'Đã tạo lệnh nạp tiền thành công. Vui lòng quét mã QR để nạp tiền.',
  history: 'Lịch sử nạp tiền',
  noHistoryRecord: 'Không có lịch sử nạp tiền',
}
