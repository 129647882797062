import { env } from '@/libs/env'

export default {
  create: 'Tạo mới',
  form: {
    title: 'Tạo yêu cầu gọi tổng đài',
    edit: 'Sửa yêu cầu gọi tổng đài',
    pnr: 'Pnr',
    airline: 'Hãng',
    phone: 'Số điện thoại',
    content: 'Nội dung',
    note: 'Ghi chú F1',
    status: 'Trạng thái',
    ph: {
      phone: 'Nhập số điện thoại',
      note: 'Nhập ghi chú',
      pnr: 'Nhập pnr',
      airline: 'Chọn hãng',
      content: 'Nhập nội dung cần hỗ trợ càng chi tiết càng tốt.',
      status: 'Chọn trạng thái',
    },
  },
  columns: {
    stt: 'STT',
    no: 'STT',
    createdAt: 'Ngày tạo',
    createdBy: 'Người tạo',
    content: 'Nội dung',
    note: 'Ghi chú F1',
    pnr: 'PNR',
    airline: 'Hãng',
    status: 'Trạng thái',
    actions: 'Thao tác',
    flight: 'Flight',
  },
  createSuccess: 'Gửi yêu cầu thành công',
  updateSuccess: 'Cập nhật yêu cầu thành công',
  btn: {
    call: 'Liên hệ',
    edit: 'Sửa',
    add: 'Thêm',
    delete: 'Xóa',
    detail: 'Chi tiết',
    reject: 'Hãng từ chối',
    cancel: 'Hủy yêu cầu gọi tổng đài',
    editReq: 'Sửa yêu cầu gọi tổng đài',
    reload: 'Làm mới',
  },
  type: {
    IDENTITY: 'Định danh',
    AGENT: 'Bảo lưu',
  },
  status: {
    title: 'Trạng thái',
    PENDING: 'Đang chờ',
    COMPLETED: 'Hoàn thành',
    REJECTED: 'Hãng từ chối',
    SELF_HANDLED: 'Tự thao tác',
    CANCELED: 'Đã hủy',
    PROCESSING: 'Đang xử lý',
    ALL: 'Tất cả',
  },
  note: {
    title: `Đại lý vui lòng hỗ trợ team ${env.mamaShortName}`,
    note1: 'Không gửi yêu cầu hỗ trợ vào ngày Thứ 7 & Chủ nhật.',
    note2: `Code nào có thể tự xử lý được trên hệ thống web/app ${env.appName}, AG chủ động xử lý.`,
    note3: 'Chủ động kiểm tra thường xuyên "trạng thái yêu cầu" đã được xử lý chưa.',
    note4: 'Các trường hợp gấp, AG chủ động gọi tổng đài. Nếu không gọi được có thể liên hệ trực tiếp team Sales (TRONG CA TRỰC) để được hỗ trợ.',
  },
}
