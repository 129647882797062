import { env } from '@/libs/env'

export default {
  create: 'Create',
  form: {
    title: 'Support request',
    pnr: 'Pnr',
    airline: 'Airline',
    phone: 'Phone number',
    content: 'Content',
    note: 'Note',
    status: 'Status',
    ph: {
      pnr: 'Enter pnr',
      airline: 'Enter airline',
      phone: 'Enter phone number',
      content: 'Enter content',
      note: 'Enter note',
      status: 'Enter Status',
    },
  },
  columns: {
    stt: 'No.',
    createdAt: 'Created Date',
    createdBy: 'Created By',
    content: 'Content',
    note: 'Note F1',
    pnr: 'PNR',
    airline: 'Airline',
    status: 'Status',
    actions: 'Actions',
    flight: 'Flight',
  },
  createSuccess: 'Create request successful',
  updateSuccess: 'Request update successful',
  btn: {
    call: 'Call',
    edit: 'Edit',
    add: 'Add',
    delete: 'Delete',
    detail: 'Detail',
    cancel: 'Cancel',
    editReq: 'Edit request',
    reload: 'Reload',
  },
  type: {
    IDENTITY: 'Identity reservation',
    AGENT: 'Credit shell refund',
  },
  status: {
    title: 'Status',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected',
    SELF_HANDLED: 'Self dandled',
    CANCELED: 'Canceled',
    ALL: 'ALL',
    PROCESSING: 'Processing',
  },
  note: {
    title: `Dear agency, please support the ${env.mamaShortName} team`,
    note1: 'Do not send support requests on Saturdays & Sundays.',
    note2: `For codes that can be self-processed on the ${env.appName} web/app system, AG should handle them proactively.`,
    note3: 'Regularly check the "request status" to see if it has been processed.',
    note4: 'In urgent cases, AG should proactively call the hotline. If unable to reach, you may contact the Sales team directly (DURING SHIFTS) for assistance.',
  },
}
