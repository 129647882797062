import Vue from 'vue'
import VueI18n from 'vue-i18n'

import * as messages from './locales'

Vue.use(VueI18n)
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }
// console.log(messages.default)
const getLocaleLanguage = localStorage.getItem('Locale') || process.env.VUE_APP_DEFAULT_LANGUAGE
export default new VueI18n({
  locale: getLocaleLanguage,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  messages: messages.default,
  missing: (locale, key, vm) => {
    // https://stackoverflow.com/a/53980799
    try {
      if (key?.includes('.')) {
        const newKey = /\.(.+)/.exec(key)[1]
        return (newKey === 'undefined' || newKey === 'null') ? '' : vm.$t(newKey)
      }
    // eslint-disable-next-line no-empty
    } catch (error) {}
    return ''
  },
})
