export default {
  general: 'General reports',
  bookTable: 'Book info table',
  paymentTable: 'Payment info table',
  columns: {
    source: 'Source',
    count: 'Amount',
    totalPayment: 'Total payment',
    tradeCreditors: 'Trade creditors',
    receivables: 'Receivables',
    profit: 'Profit',
  },
  totalCustomer: 'Total customers',
  totalBookings: 'Total bookings',
  totalPaymentFinal: 'Total payment final',
  totalTradeCreditors: 'Total trade creditors',
  totalReceivables: 'Total receivables',
  totalProfit: 'Total profit',
}
