export default {
  // for error notification list
  close: 'Close',
  management: 'Invoice Error Notification Management',
  list: 'Error Notification List',
  create: 'Create New',
  description: 'Description',
  errorNotiNo: 'Error Noti No',
  errorNotiDate: 'TA Noti Date',
  errorNotiType: 'Noti Type',
  notiStatus: 'Status',
  selectionCount:
    'No selected error notification | One selected error notification | {count} selected error notifications',
  preview: 'Preview error notification',

  // 'ph'acronym of 'placeholder'
  phSelectDateOption: 'Please select date option',
  phSelectDate: 'Please select a date',
  phErrorNotiNo: 'Enter Error Noti No',
  phSelectNotiType: 'Please select noti type',
  phSelectStatus: 'Please select status',
  phSelectEmailStatus: 'Please select email status',
  phSearch: 'Search text by invoice No, TA number',

  dateOptions: {
    creationDate: 'Creation date',
    issueDate: 'Issue date',
  },
  type: {
    nnt: 'NNT',
    cqt: 'CQT',
  },
  status: {
    draft: 'Draft',
    waitingToSign: 'Waiting to sign',
    sign: 'Signed',
    refuseToSign: 'Refuse to sign',
    waitingToIssue: 'Waiting to issue',
    issue: 'issued',
    refuseToIssue: 'Refuse to issue',
  },
  columns: {
    checkbox: '',
    no: 'Error noti No',
    type: 'Type',
    invoices: 'Invoices quantity',
    taAcceptNo: 'TA accept No',
    taAcceptDate: 'TA accept date',
    status: 'Status',
    created: 'Created at',
    updated: 'Updated at',
    actions: 'Actions',
  },
  moreDropdown: {
    add: 'Add error notification',
    export: 'Export error notification',
  },
  msgCanNotSignInvoices:
    'Please select only the invoices that Awaiting for signature to sign.',

  // for error notification detail
  errFetchNoti: 'Error fetching notification data',
  notFundNotiById_1: 'No error notification found with this id. Check',
  notFundNotiById_2: 'for other notification.',
  btn: {
    preview: 'Preview',
    previewInDetailPage: 'Preview error notification',
    delete: 'Delete',
    sendToSign: 'Send to Sign',
    cancel: 'Cancel',
    save: 'Save',
    saveAndSend: 'Save & Send to Sign',
  },
  info: 'Infomation',
  taxCode: 'Tax code',
  noSerial: 'No/ Serial',
  company: 'Company',
  invoiceDate: 'Invoice date',
  issueDate: 'Issue date',
  guestName: 'Guest name',
  paymentMethod: 'Payment method',
  cancelReason: 'Cancellation reason',
  note: 'Note',
  ticketType: 'Ticket type',

  history: 'History',
  historyColumns: {
    orderNo: 'No.',
    date: 'Date',
    action: 'Action',
    executor: 'Executor',
  },
  historyActions: {
    CREATE: 'Create new',
    UPDATE: 'Update',
    WAITING_TO_APPROVE: 'Waiting to approve',
    SIGN: 'Sign',
    REFUSE_TO_SIGN: 'Refuse to sign',
    WAITING_TO_ISSUE: 'Waiting to issue',
  },
  goodsServices: 'Goods and Services',
  // 'gs' mean 'Goods and Services':
  gsColumns: {
    orderNo: 'No.',
    invoiceNumber: 'Invoice Number',
    serial: 'No/Serial',
    issueDate: 'Issue Date',
    taCode: 'taCode',
    invoiceType: 'Cancel/Adjustment/Replacement/Explanation',
    reason: 'Reason',
    action: 'Action',
  },
  totalAmountPreTax: 'Total amount pre tax',
  totalVAT: 'Total VAT',
  fee: 'Fee',
  discount: 'Discount',
  collectionFee: 'Collection fee',
  totalPayment: 'Total payment',

  // for create/update/adjust/replace invoice
  pageTitle: {
    add: 'Create Error Notification',
    update: 'Update Error Notification',
    detail: 'Error Notification Detail',
  },
  getInfo: 'Get Info',
  phTaxCode: 'Enter tax code',
  phCompany: 'Enter company',
  phPaymentMethod: 'Select payment method',
  phGuestName: 'Search or enter guest name',
  phNote: 'Enter note',
  phAddress: 'Enter address',
  phCancelReason: 'Enter cancellation reason',
  phReceiver: 'Enter receiver',
  autoSendEmail: 'Auto send email',
  addRow: 'Add row',
  popup: {
    sendEmailTitle: 'Send Email',
    selectSendEmailOption: 'Option Auto Send Email After Sign Invoice',
    cancelInvoiceTitle: 'Cancel Invoice',
    chooseTicketTitle: 'Choose Ticket',
  },
  phSendEmail: 'Multiple emails can be entered, each email is separated by a ";"',
  chooseTicket: 'Choose ticket',
  continue: 'Continue',
  combineTickets: 'Combine tickets',
  notification: 'Notification',
}
