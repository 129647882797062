import store from '@/store'

// import ability from './ability'

export const canNavigate = to => to.matched.some(async route => {
  if (route.meta.role) return false
  if (!route.meta.action) return true
  const ability = store.getters['userStore/ability']
  const functionScopes = await store.getters[
    'userStore/getFunctionScopesbyFunctionName'
  ](route.meta.action)
  const requiredScopes = functionScopes?.requiredScopes
  return ability.can(requiredScopes)
})

export const _ = undefined
