export default {
  title: 'Thông báo',
  sendNotification: 'Gửi thông báo',
  subject: 'Chủ đề',
  content: 'Nội dung',
  recipientRefType: 'Lọai người nhận',
  channel: 'Kênh',
  sendType: 'Thời gian gửi',
  reschedulePeriodInSecond: 'Thời gian gửi lại thông báo',
  sendTime: 'Thời gian bắt đầu gửi thông báo',
  deadline: 'Thời gian kết thúc gửi thông báo',
  sendOneTime: 'Chỉ gửi thông báo 1 lần',
  hour: 'giờ',
  minute: 'phút',

  success: 'Thành công',
  error: 'Thất bại',
  sendNotificationSuccess: 'Gửi thông báo thành công',
  sendNotificationError: 'Gửi thông báo thất bại',
  sendTimeMustBeforeDeadline: 'Thời gian bắt đầu gửi phải trước thời gian kết thúc',
  confirmSCNote: 'Quý đại lý vui lòng gửi code cho booker kiểm tra lại thông tin.',
}
