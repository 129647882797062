export default {
  title: 'Nghiệp vụ',
  titleList: 'Danh sách nghiệp vụ',
  content: 'Nghiệp vụ',

  createScheduleTagError: 'Tạo nghiệp vụ không thành công',
  createScheduleTagSuccess: 'Tạo nghiệp vụ thành công',
  editScheduleTagSuccess: 'Sửa nghiệp vụ thành công',
  deleteScheduleTagSuccess: 'Xóa nghiệp vụ thành công',

  create: {
    title: 'Tạo nghiệp vụ',
    cancel: 'Hủy',
  },

  edit: {
    title: 'Sửa nghiệp vụ',
    cancel: 'Hủy',
    save: 'Lưu',
  },
}
