export default {
  title: 'Schedule Tag',
  titleList: 'Schedule Tag List',
  content: 'Content',

  createScheduleTagError: 'Create schedule tag error',
  createScheduleTagSuccess: 'Create schedule tag success',
  editScheduleTagSuccess: 'Edit schedule tag success',
  deleteScheduleTagSuccess: 'Delete schedule tag success',

  create: {
    title: 'Create',
    cancel: 'Cancel',
  },

  edit: {
    title: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
  },
}
