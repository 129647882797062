export default {
  title: 'Ca trực',
  titleList: 'Danh sách ca trực',
  name: 'Tên ca',
  scheduleCalendarTimes: 'Thời gian ca',
  note: 'Ghi chú',
  startTime: 'Giờ bắt đầu',
  endTime: 'Giờ kết thúc',

  createScheduleCalendarError: 'Tạo ca trực không thành công',
  createScheduleCalendarSuccess: 'Tạo ca trực thành công',
  editScheduleCalendarSuccess: 'Sửa ca trực thành công',
  deleteScheduleCalendarSuccess: 'Xóa ca trực thành công',

  addMore: 'Thêm',

  create: {
    title: 'Tạo ca trực',
    cancel: 'Hủy',
  },

  edit: {
    title: 'Sửa ca trực',
    cancel: 'Hủy',
    save: 'Lưu',
  },
}
