export default {
  Customer: 'Customer',
  textShowCustomer: 'customers',
  textBtnCreate: 'Customer',
  placeholderSearch: 'Name, email, phone number, customer code ...',
  checkbox: '',
  code: 'Customer Code',
  placeholderCode: 'Please enter Customer code',
  tabInfo: 'Customer Information',
  created: 'Created',
  updated: 'Updated',

  STT: 'STT',
  Customer_First_Name: 'Customer First Name',
  Customer_Last_Name: 'Customer Last Name',
  Gender: 'Gender',
  Phone_Number: 'Phone Number',
  Email_Address: 'Email Address',
  Birthday: 'Birthday',
  Description: 'Description',
  Street: 'Street',

  customerList: 'Customer List',
  errFetchData_1: 'Error fetching customer data',
  errFetchData_2: "No customer found with this customer's id. Check",
  errFetchData_3: 'for other customer.',

  fullName: 'Full Name',
  dateOfBirth: 'Date Of Birth',
  typeOfPassenger: 'Type of Passenger',
  Action: 'Action',

  addContact: 'Add Contact',
  editContact: 'Edit Contact',

  errFetchContact: 'Error fetching contacts data',
  noContact_1: 'No contacts found with this contacts id. Check',
  noContact_2: 'contacts List',
  noContact_3: 'for other contacts.',

  moreDropdown: {
    title: 'More',
    add: 'Add Customer',
    import: 'Import Customer',
    export: 'Export Customer',
    delete: 'Delete Customer',
  },

  Account: 'Account',
  Contact: 'Contact',
  historyBooking: 'History Booking',
  noInfo: 'No information',
  Back: 'Back',
  accountInfoText: 'Account information',
  lastName: 'Last name',
  placeholderLastName: 'Enter last name',
  firstName: 'First name',
  placeholderFirstName: 'Enter first name',
  email: 'Email',
  phoneNumber: 'Phone number',
  placeholderPhoneNumber: 'Enter phone number',
  dob: 'Date of birth',
  placeholderDoB: 'Choose date of birth',
  gender: 'Gender',
  placeholderSelectGender: 'Select gender',
  backupPhoneNumber: 'Backup phone number',
  description: 'Description',
  placeholderDescription: 'Enter description',
  address: 'Address',
  country: 'Country',
  placeholderCountry: 'Select country',
  loadingCountryText: 'Loading country...',
  noResultFund: 'No results found for',
  noOptions: 'Sorry, no matching options.',
  city: 'Province/City',
  placeholderCity: 'Select city',
  loadingCityText: 'Loading city...',
  district: 'District',
  placeholderDistrict: 'Select District',
  loadingDistrictText: 'Loading District...',
  ward: 'Sub District/Ward',
  placeholderWard: 'Select ward',
  loadingWardText: 'Loading ward...',
  street: 'Address street',
  placeholderStreet: 'Enter street',
  needSelect_1: 'You need select',
  needSelect_2: 'first.',
  back: 'Back',
  clear: 'Clear',
  next: 'Next',
  save: 'Save',
  placeholderEmail: 'Enter email',

  contact: 'Contact',
  addNew: 'Add New',
  passengerType: 'Type of Passenger',
  contactInfoText: 'Contact Information',
  information: 'Information',
  noRecordFund: 'No matching records found',
  placeholderSearchContact: 'Name, email...',
  edit: 'Edit',
  cancel: 'Cancel',
  memberShip: 'Membership card',
}
