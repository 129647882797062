export default {
  form: {
    fullName: 'Tên tổ chức',
    shortName: 'Tên viết tắt',
    paymentCode: 'Mã thanh toán',
    paymentCodePrefix: 'Tiền tố mã thanh toán',
    paymentCodeSuffixFrom: 'Hậu tố mã thanh toán từ',
    paymentCodeSuffixTo: 'Hậu tố mã thanh toán đến',
    paymentCodeSuffixCharacterType: 'Loại ký tự hậu tố mã thanh toán',
    transactionAmount: 'Số tiền giao dịch',
    sepayFeeConfig: 'Phí / giao dịch',
    ph: {
      fullName: 'Nhập tên tổ chức',
      shortName: 'Nhập tên viết tắt',
      paymentCode: 'Mã thanh toán',
      paymentCodePrefix: 'Tiền tố mã thanh toán',
      paymentCodeSuffixFrom: 'Hậu tố mã thanh toán từ',
      paymentCodeSuffixTo: 'Hậu tố mã thanh toán đến',
      paymentCodeSuffixCharacterType: 'Loại ký tự hậu tố mã thanh toán',
      transactionAmount: 'Số tiền giao dịch',
      sepayFeeConfig: 'Nhập phí / giao dịch',
    },
  },
  columns: {
    stt: 'STT',
    agency: 'Đại lý',
    companyId: 'Mã tổ chức',
    bankId: 'Mã ngân hàng',
    accountHolderName: 'Tên chủ tài khoản',
    bankAccountName: 'Tên chủ tài khoản',
    accountNumber: 'Số tài khoản',
    accumulated: 'Số dư',
    label: 'Tên gợi nhớ',
    bankApiConnected: 'Xác thực ngân hàng',
    lastTransaction: 'Giao dịch cuối',
    createdAt: 'Tạo ngày',
    updatedAt: 'Sửa ngày',
    fullName: 'Tên tổ chức',
    shortName: 'Tên viết tắt',
    status: 'Trạng thái',
    transactionId: 'Id giao dịch',
    transactionDate: 'Ngày nhận giao dịch',
    bankAccountId: 'ID tài khoản ngân hàng',
    vaId: 'Id Va',
    va: 'Tài khoản ảo',
    referenceNumber: 'Mã tham chiếu FT',
    transactionContent: 'Nội dung giao dịch',
    paymentCode: 'Mã thanh toán (nếu có)',
    transferType: 'Loại giao dịch',
    amount: 'Số tiền giao dịch',
    actions: 'Thao tác',
    details: 'Chi tiết',
    date: 'Ngày',
    transaction: 'Tổng giao dịch',
    transactionIn: 'Giao dịch vào',
    transactionOut: 'Giao dịch ra',
    sepayFeeConfig: 'Phí / giao dịch',
  },
  btn: {
    detailCompany: 'Sửa',
    detailConfig: 'Cài đặt',
    detailCouter: 'Bộ đếm',
  },
  status: {
    Pending: 'Đang chờ',
    Active: 'Hoạt động',
    Suspended: 'Bị đình chỉ',
    Terminated: 'Bị chấm dứt',
    Cancelled: 'Bị hủy',
    Fraud: 'Gian lận',
    debit: 'Tiền ra',
    credit: 'Tiền vào',
  },
  search: {
    key: 'Từ khóa',
    transaction: 'Nội dung giao dịch, mã tham chiếu FT, mã thanh toán, số VA',
    company: 'Tên đầy đủ, tên viết tắt',
    bankAccount: 'Tên chủ thẻ, số tài khoản và tên gợi nhớ',
  },
  suffix: {
    NumberAndLetter: 'Cho phép chữ cái và số',
    NumberOnly: 'Chỉ cho phép chữ số',
  },
  connected: 'Đã xác thực',
  disconnected: 'Chưa xác thực',
  count: 'Tổng giao dịch',
  countIn: 'Tổng vào',
  countOut: 'Tổng ra',
  unlimited: 'Không giới hạn',
  configCompanySuccess: 'Cài đặt thành công',
}
