export default {
  general: 'Báo cáo tổng hợp',
  bookTable: 'Doanh số đặt vé',
  paymentTable: 'Doanh số xuất vé',
  columns: {
    source: 'Hệ thống',
    count: 'Số lượng',
    totalPayment: 'Tổng giá vé',
    tradeCreditors: 'Tổng phải trả',
    receivables: 'Tổng phải thu',
    profit: 'Tổng lợi nhuận',
  },
  totalCustomer: 'Tổng khách hàng',
  totalBookings: 'Tổng đặt vé',
  totalPaymentFinal: 'Tổng giá vé',
  totalTradeCreditors: 'Tổng phải trả',
  totalReceivables: 'Tổng phải thu',
  totalProfit: 'Tổng lợi nhuận',
}
