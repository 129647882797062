export default {
  // for invoice list
  title: 'Invoice',
  management: 'Invoice Management',
  list: 'Invoice List',
  lists: {
    all: 'All invoices',
    awaitingApproval: 'Awaiting approval invoices',
    approved: 'Awaiting signature invoices',
  },
  date: 'Date type',
  from: 'From',
  to: 'To',
  invoiceNo: 'Invoice No',
  invoiceType: 'Invoice type',
  invoiceStatus: 'Invoice status',
  emailStatus: 'Email status',
  selectionCount:
    'No selected invoice | One selected invoice | {count} selected invoices',
  sendByEmail: 'Send invoice by email',
  preview: 'Preview invoice',
  deselectInvoices: 'Unselect invoices',
  cqtData: 'Error of tax authorities',
  cqtDataCode: 'Error code of tax authorities',

  // 'ph'acronym of 'placeholder'
  phSelectDateOption: 'Please select date option',
  phSelectDate: 'Please select a date',
  phInvoiceNo: 'Please enter an invoice number',
  phSelectInvoiceType: 'Please select invoice type',
  phSelectInvoiceStatus: 'Please select invoice status',
  phSelectEmailStatus: 'Please select email status',
  phSearch: 'Search text by tax code, company name, customer name',

  dateOptions: {
    creationDate: 'Creation date',
    releaseDate: 'Release date',
  },
  invoiceTypes: {
    original: 'Original invoice',
    adjustment: 'Adjustment invoice',
    replacement: 'Replacement invoice',
    cancellation: 'Cancellation invoice',
    adjusted: 'Adjusted invoice',
  },
  status: {
    draft: 'Draft',
    awaitingApproval: 'Awaiting approval',
    approved: 'Waiting to sign', // ~ 'Approved'
    refuseApproval: 'Refuse approval',
    signed: 'Signed',
    refuseSignature: 'Refuse signature',
    awaitingRelease: 'Awaiting release',
    released: 'Released',
    refuseRelease: 'Refuse release',
  },
  emailStatusOptions: {
    all: 'All',
    sent: 'Sent',
    notSent: 'Not sent',
  },
  columns: {
    checkbox: '',
    invoiceNumber: 'Invoice No',
    issueDate: 'Issue Date',
    customer: 'Customer',
    total: 'Total',
    totalPreTax: 'Total Pre Tax',
    tax: 'Tax',
    collectionFee: 'Collection Fee',
    taCode: 'TA Code',
    type: 'Type',
    status: 'Status',
    emailStatus: 'Email Status',
    errNoti: 'Error Notification',
    created: 'Created',
    updated: 'Updated',
    actions: 'Actions',
  },
  moreDropdown: {
    add: 'Add invoice',
    export: 'Export invoice',
  },
  msgCanNotSignInvoices:
    'Please select only the invoices that Awaiting for signature to sign.',
  msgCanNotSendToApproveInvoices:
    'Please select only Draft or Refuse approval status invoices to send to approve.',
  msgCanNotApproveInvoices:
    'Please select only Awaiting approval invoices to approve.',
  msgCanNotDisapproveInvoices:
    'Please select only Awaiting approval invoices to disapprove.',
  msgCanNotRefuseSignInvoices:
    'Please select only the invoices that Awaiting for signature to refuse sign.',
  msgCanNotSendEmailInvoices:
    'To send emails for multiple invoices, Please do not select Canceled/Adjusted invoice type whose status is different from Issued.',
  msgSendToApproveSuccess: 'Send to approve successfully',
  msgSendToApproveErr: 'Send to approve not successfully',
  msgApproveSuccess: 'Approve successfully',
  msgApproveErr: 'Approve not successfully',
  msgDisapproveSuccess: 'Disapprove successfully',
  msgDisapproveErr: 'Disapprove not successfully',
  msgRefuseSignSuccess: 'Refuse sign successfully',
  msgRefuseSignErr: 'Refuse sign not successfully',

  // for invoice detail
  detail: 'Invoice Detail',
  errFetchInvoice: 'Error fetching invoice data',
  notFundInvoiceById_1: 'No invoice found with this id. Check',
  notFundInvoiceById_2: 'for other invoices.',
  btn: {
    preview: 'Preview',
    update: 'Update',
    sign: 'Sign',
    refuseSign: 'Refuse sign',
    cancelInvoice: 'Cancel invoice',
    adjustment: 'Adjustment',
    replacement: 'Replacement',
    sendEmail: 'Send email',
    sendToApprove: 'Send to approve',
    saveAndSendToApprove: 'Save & Send to approve',
    saveAndSign: 'Save & Sign',
    approve: 'Approve',
    disapprove: 'Disapprove',
  },
  info: 'Infomation',
  taxCode: 'Tax code',
  noSerial: 'No/ Serial',
  company: 'Company',
  invoiceDate: 'Invoice date',
  issueDate: 'Issue date',
  guestName: 'Guest name',
  paymentMethod: 'Payment method',
  cancelReason: 'Cancellation reason',
  note: 'Note',
  ticketType: 'Ticket type',

  history: 'History',
  historyColumns: {
    orderNo: 'No.',
    date: 'Date',
    action: 'Action',
    amount: 'Amount',
    executor: 'Executor',
  },
  historyActions: {
    CREATE: 'Create new',
    UPDATE: 'Update',
    REFUSE_TO_SIGN: 'Refuse to sign',
    SIGN: 'Sign',
    ADJUST: 'Adjust invoice',
    REPLACE: 'Replace invoice',
    CANCEL: 'Cancel',
    SEND_MAIL: 'Send email',
  },
  goodsServices: 'Goods and Services',
  // 'gs' mean 'Goods and Services':
  gsColumns: {
    orderNo: 'No.',
    characteristic: 'Characteristic',
    description: 'Description',
    unit: 'Unit',
    quantity: 'Quantity',
    unitPrice: 'Unit price',
    amountPreTax: 'Amount pre tax',
    VATrate: 'VAT rate',
    VAT: 'VAT',
    amount: 'Amount',
    action: 'Action',
  },
  totalAmountPreTax: 'Total amount pre tax',
  totalVAT: 'Total VAT',
  fee: 'Fee',
  discount: 'Discount',
  collectionFee: 'Collection fee',
  totalPayment: 'Total payment',

  // for create/update/adjust/replace invoice
  pageTitle: {
    add: 'Create Invoice',
    update: 'Update Invoice',
    adjust: 'Adjust Invoice',
    replace: 'Replace Invoice',
  },
  getInfo: 'Get Info',
  phTaxCode: 'Enter tax code',
  phCompany: 'Enter company',
  phPaymentMethod: 'Select payment method',
  phGuestName: 'Search or enter guest name',
  phNote: 'Enter note',
  phAddress: 'Enter address',
  phCancelReason: 'Enter cancellation reason',
  phReceiver: 'Enter receiver',
  autoSendEmail: 'Auto send email',
  topRowContent: {
    ADJUST:
      'Adjustment invoice for Invoice number {invoiceNumber}, Form {form}, Serial {serial}, Created date {createdDate}',
    REPLACE:
      'Replacement invoice for Invoice number {invoiceNumber}, Form {form}, Serial {serial}, Created date {createdDate}',
  },
  paymentMethodOptions: {
    cash: 'Cash',
    transfer: 'Transfer',
    all: 'Cash/ Transfer',
  },
  ticketTypeOptions: {
    domestic: 'Domestic ticket',
    international: 'International ticket',
    mix: 'Domestic ticket & International ticket',
  },
  characteristicOptions: {
    goods: 'Goods',
    fee: 'Fee',
    discount: 'Discount',
    description: 'Description',
  },
  addRow: 'Add row',
  popup: {
    sendEmailTitle: 'Send Email',
    selectSendEmailOption: 'Option Auto Send Email After Sign Invoice',
    cancelInvoiceTitle: 'Cancel Invoice',
    chooseTicketTitle: 'Choose Ticket',
  },
  phSendEmail:
    'Multiple emails can be entered, each email is separated by a ";"',
  // 'se' mean 'Send Email':
  seColumns: {
    orderNo: 'No.',
    receiver: 'Receiver',
    recipientEmail: "recipient's email",
    cc: 'CC',
    bcc: 'BCC',
    invoiceNo: 'Invoice number',
    serial: 'From/ Serial',
    totalPayment: 'Total payment',
    emailStatus: 'Email Status',
    preview: 'Preview',
  },
  customerCode: 'Customer code',
  customerName: 'Customer name',
  chooseTicket: 'Choose ticket',
  showTicketNumber: 'Show ticket number/ booking code',
  continue: 'Continue',
  combineTickets: 'Combine tickets',
  notification: 'Notification',
  download: 'Download',
  print: 'Print',
  copyToClipboard: 'Click to copy to clipboard',
  lookupInvoice: 'Lookup Invoice',
  lookupCode: 'Lookup Code',
  lookup: 'Lookup',
  msgNoLookupCode: 'Enter the Lookup Code 14 characters long.',
  msgErrLookupInvoice: 'Invoice not found. Please enter another lookup code.',
  msgErrChangeQuantityOfTickets:
    'Only change the quantity to values >=1 and <={quantity} is number of combined tickets',
  msgErrChangeTotal:
    'Error! Please complete your Goods and Services table first.',
  msgSuccessChangeTotalCollectionFee: 'Editing Collection Fee Successfully.',
  msgSuccessChangeTotalPayment:
    'Edited unit price(s) of goods and services to match Adjusted Total Payment.',
  msgErrCreateInvoice: 'Error during create invoice.',
  msgErrCreateInvoiceWithBeKeptTickets:
    'Invoice cannot be created because {tickets} is waiting for approval.',
  msgSuccessCreateInvoice: 'Create invoice successfully.',
  msgErrUpdateInvoice: 'Error during update invoice.',
  msgSuccessUpdateInvoice: 'Update invoice successfully.',
  msgErrAdjustInvoice: 'Error during create adjustment invoice.',
  msgSuccessAdjustInvoice: 'Create adjustment invoice successfully.',
  msgErrReplaceInvoice: 'Error during create replacement invoice.',
  msgSuccessReplaceInvoice: 'Create replacement invoice successfully.',
  msgSuccessSaveButErrSendToApprove:
    'Save invoice successfully but Error during send to approve.',
  msgSuccessSaveAndSendToApprove: 'Save & Send to approve successfully',
  msgErrSignInvoice: 'Error during sign invoice.',
  msgSuccessSignInvoice: 'Sign invoice successfully.',
  msgErrCombineTicketsNotSameTrips: 'Only choose tickets with the same trips.',
}
