export default {
  Customer: 'Khách hàng',
  textShowCustomer: 'khách hàng',
  textBtnCreate: 'Thêm mới',
  placeholderSearch: 'Tên, email, số điện thoại, mã khách hàng ...',
  checkbox: '',
  code: 'Mã khách hàng',
  placeholderCode: 'Nhập mã khách hàng',
  tabInfo: 'Thông tin khách hàng',
  created: 'Thời gian tạo',
  updated: 'Thời gian sửa đổi',

  STT: 'STT',
  Customer_First_Name: 'Tên khách hàng',
  Customer_Last_Name: 'Họ khách hàng',
  Gender: 'Giới tính',
  Phone_Number: 'Số điện thoại',
  Email_Address: 'Địa chỉ Email',
  Birthday: 'Ngày sinh',
  Description: 'Mô tả',
  Street: 'Địa chỉ',

  customerList: 'Danh sách khách hàng',
  errFetchData_1: 'Lỗi tìm nạp dữ liệu khách hàng',
  errFetchData_2: 'Không có khách hàng tìm thấy với ID của khách hàng này. Kiểm tra',
  errFetchData_3: 'cho khách hàng khác.',

  fullName: 'Họ và tên',
  dateOfBirth: 'Ngày sinh',
  typeOfPassenger: 'Loại hành khách',
  Action: 'Hành động',

  addContact: 'Thêm liên hệ',
  editContact: 'Cập nhật thông tin liên hệ',

  errFetchContact: 'Lỗi tìm nạp dữ liệu liên hệ',
  noContact_1: 'Không tìm thấy liên hệ với ID này. Kiểm tra ',
  noContact_2: 'Danh sách liên hệ',
  noContact_3: 'để thử lại.',

  moreDropdown: {
    title: 'Lựa chọn',
    add: 'Thêm mới khách hàng',
    import: 'Nhập từ Excel',
    export: 'Xuất ra Excel',
    delete: 'Xoá khách hàng',
  },

  Account: 'Tài khoản',
  Contact: 'Liên hệ',
  historyBooking: 'Lịch sử đặt vé',
  noInfo: 'Không có thông tin',
  Back: 'Trở lại',
  accountInfoText: 'Thông tin tài khoản',
  lastName: 'Họ',
  placeholderLastName: 'Điền vào họ',
  firstName: 'Tên đệm & Tên',
  placeholderFirstName: 'Điền vào Tên đệm & Tên',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  placeholderPhoneNumber: 'Nhập số điện thoại',
  dob: 'Ngày sinh',
  placeholderDoB: 'Chọn ngày sinh',
  gender: 'Giới tính',
  placeholderSelectGender: 'Chọn giới tính',
  backupPhoneNumber: 'Số điện thoại dự phòng',
  description: 'Mô tả',
  placeholderDescription: 'Nhập mô tả',
  address: 'Địa chỉ',
  country: 'Quốc gia',
  placeholderCountry: 'Chọn quốc gia',
  loadingCountryText: 'Đang tải danh sách quốc gia ...',
  noResultFund: 'Không tìm thấy kết quả cho',
  noOptions: 'Xin lỗi, không có tùy chọn phù hợp.',
  city: 'Tỉnh/Thành phố',
  placeholderCity: 'Chọn Thành phố',
  loadingCityText: 'Đang tải danh sách thành phố...',
  district: 'Quận',
  placeholderDistrict: 'Chọn Quận',
  loadingDistrictText: 'Đang tải danh sách Quận...',
  ward: 'Phường',
  placeholderWard: 'Chọn Phường',
  loadingWardText: 'Đang tải danh sách Phường...',
  street: 'Đường',
  placeholderStreet: 'Nhập vào tên đường',
  needSelect_1: 'Bạn cần chọn',
  needSelect_2: 'trước.',
  back: 'Trở lại',
  clear: 'Xoá',
  next: 'Tiếp tục',
  save: 'Lưu',
  placeholderEmail: 'Điền vào email',

  contact: 'Liên hệ',
  addNew: 'Thêm mới',
  passengerType: 'Loại hành khách',
  contactInfoText: 'Thông tin liên hệ',
  information: 'Thông tin',
  noRecordFund: 'Không tìm thấy kết quả',
  placeholderSearchContact: 'Tên, email...',
  edit: 'Chỉnh sửa',
  cancel: 'Huỷ',
  memberShip: 'Thẻ thành viên',
}
