export default {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',

  Admin: 'Admin',
  Accountant: 'Accountant',
  Sales: 'Sales',
  Booker: 'Booker',
  OPE: 'OPE',
  'Chief accountant': 'Chief Accountant',

  Active: 'Active',
  Inactive: 'Inactive',

  New: 'New',
  'In contract': 'In contract',
  Signed: 'Signed',
  Delivered: 'Delivered',
  Done: 'Done',

  Adult: 'Adult',
  Child: 'Child',
  Infant: 'Infant',

  Yes: 'Yes',
  No: 'No',

  noOptions: 'Sorry, no matching options.',
}
