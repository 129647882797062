/**
 *
 * Link to Message List:
 * https://docs.google.com/spreadsheets/d/1Cw8iJIdFyA4blo4vQA6VhosN9EYDZKfK/edit#gid=45417593
 * for i18n only, it mean only importing to src/libs/i18n/locales/en/index.js
 * not for validation purposes as file src/constants/messageList.js
 *
 * */

export default {
  MSG_037: 'Are you sure you want to cancel?',
}
