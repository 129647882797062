export default {
  yes: 'Yes',
  no: 'No',
  iamConfirm: 'I agree',
  confirm: 'CONFIRM',
  logout: 'Logout',
  close: 'Close',
  edit: 'Edit',
  save: 'Save',
  activate: 'Activate',
  deactivate: 'Deactivate',
  back: 'Back',
  cancel: 'Cancel',
  confirmBan: 'Are you sure to ban?',
  confirmUnban: 'Are you sure to unban?',
  clearForm: 'Are you sure to clear the form?',
  changeStatus: 'Are you sure to change status?',
  changeStatusWithText: 'Are you sure to change status {text}?',
  setAutoBank: 'Note: Using the automatic money transaction confirmation service will incur a transaction fee. Each transaction will be charged {fee} vnđ/transaction.',
  unsetAutoBank: 'Note: that you are turning off the automatic transaction confirmation feature.',
  confirmDelete: 'Are you sure to delete?',
  confirmDeactive: 'Are you sure to active/deactive?',
  confirmSendEmail: 'Are you sure to send mail?',
  confirmPayment: 'Are you sure to pay?',
  confirmPaymentWithoutDiscount: 'Are you sure to pay without discount?',
  confirmExport: 'Are you sure to export data to Excel?',
  confirmExportChoosen: 'Are you sure you want to export the selected data to Excel?',
  downloadRecentlyFile: 'Are you sure to download the recently imported file?',
  downloadSampleFile: 'Are you sure to download customers sample file?',
  confirmLogout: 'Are you sure you want to logout?',
  confirmModify: 'Are you sure to modify?',
  confirmAddSeat: 'Are you sure to add seat?',
  refuseToSign: 'Are you sure to refuse to sign?',
  redirect: 'Do you want to go to the next page?',
  confirmReport: 'Violation report (complaint).',
  confirmCancelEdit: 'The information you have edited will be lost. Are you sure you want to cancel editing?',
  confirmCancelInput: 'The information you have entered will be lost. Are you sure you want to exit?',
  sold: 'Are you sure you sold this identifier?',
}
