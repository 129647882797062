export default {
  HAN: 'Hà Nội',
  SGN: 'TP. Hồ Chí Minh',
  DAD: 'Đà Nẵng',
  PQC: 'Phú Quốc',
  CXR: 'Nha Trang',
  BMV: 'Buôn Ma Thuột',
  CAH: 'Cà Mau',
  VCA: 'Cần Thơ',
  VCL: 'Chu Lai',
  VCS: 'Côn Đảo',
  DLI: 'Đà Lạt',
  DIN: 'Điện Biên',
  VDH: 'Đồng Hới',
  HPH: 'Hải Phòng',
  HUI: 'Huế',
  PXU: 'Pleiku',
  UIH: 'Quy Nhơn',
  VKG: 'Rạch Giá',
  THD: 'Thanh Hoá',
  TBB: 'Tuy Hoà',
  VDO: 'Vân Đồn',
  VII: 'Vinh',
}
