export default {
  Male: 'Nam',
  Female: 'Nữ',
  Other: 'Khác',

  Admin: 'Admin',
  Accountant: 'Kế toán viên',
  Sales: 'Sales',
  Booker: 'Booker',
  OPE: 'OPE',
  'Chief accountant': 'Kế toán trưởng',

  Active: 'Hoạt động',
  Inactive: 'Không hoạt động',

  New: 'Mới',
  'In contract': 'Hợp đồng',
  Signed: 'Kí hợp đồng',
  Delivered: 'Bàn giao',
  Done: 'Hoàn thành',

  Adult: 'Người lớn',
  Child: 'Trẻ em',
  Infant: 'Trẻ sơ sinh',

  Yes: 'Có',
  No: 'Không',

  noOptions: 'Xin lỗi, không có tùy chọn phù hợp.',
}
