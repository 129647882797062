export default {
  source: 'Source',
  bookingCode: 'Booking Code',
  status: 'Status',
  passenger: 'Passenger',
  booker: 'Booker',
  flight: 'Flight',
  createdAt: 'Created At',
  checkInOnline: 'CheckInOnline',
  action: 'Action',
  note: 'Note',
  hourPreparation: 'Hour Preparation',
  searchText: 'Search Text',
}
