export default {
  type: 'Loại',
  code: 'Mã',
  typePlaceholder: 'Chọn loại',
  codePlaceholder: 'Nhập mã',
  AIRPORT: 'Sân bay',
  CARRIER: 'Hãng bay',
  CITY: 'Thành phố',
  COUNTRY: 'Quốc gia',
  EQUIPMENT: 'Loại tàu bay',
  TIMESTAMP: 'TIMESTAMP',
  result: 'Kết quả',
  noResult: 'Không tìm thấy kết quả',
  cityCode: 'Mã thành phố',
  countryCode: 'Mã quốc gia',
  name: 'Tên',

  currencyConversion: 'Chuyển đổi ngoại tệ',
  amount: 'Số tiền',
  amountPlaceHolder: 'Nhập Số tiền',
  from: 'Từ',
  to: 'Thành',
  convert: 'Chuyển đổi',
  bankSellingRate: 'Tỷ giá',
  convertedAmount: 'Số tiền sau chuyển đổi',
  description: 'Mô tả',
}
