export default {
  title: 'Lịch sử hoạt động',
  management: 'Quản lý lịch sử hoạt động',
  list: 'Danh sách lịch sử hoạt động',
  features: {
    booking: 'Đặt chỗ',
    reservation: 'Đặt chỗ',
    ticket: 'Vé',
    agency: 'Đại lý',
    customer: 'Khách hàng',
    employee: 'Nhân viên',
    invoice: 'Hoá đơn',
    topUp: 'Nạp tiền',
    promotion: 'Khuyến mãi',
    feeConfig: 'Phí xuất vé',
    feeService: 'Phí dịch vụ',
    agencyBankAccount: 'Tài khoản ngân hàng đại lý',
  },
  columns: {
    checkbox: '',
    referenceId: 'Mã tham chiếu',
    refResource: 'Nguồn tham chiếu',
    modifiedBy: 'Người thực hiện',
    feature: 'Danh mục',
    action: 'Hành động',
    oldValue: 'Giá trị cũ',
    newValue: 'Giá trị mới',
    timestamp: 'Thời gian',
    created: 'Thời gian tạo',
    updated: 'Thời gian sửa đổi',
  },
  placeholderAction: 'Vui lòng nhập vào một hành động',
  placeholderRefId: 'Vui này nhập mã tham chiếu',
  placeholderSearch: 'Tìm kiếm theo danh mục, hành động, nguồn tham chiếu',
}
