export default {
  title: 'Notification',
  sendNotification: 'Send Notification',
  subject: 'Subject',
  content: 'Content',
  recipientRefType: 'Recipient Ref Type',
  channel: 'Channel',
  sendType: 'Send Type',
  reschedulePeriodInSecond: 'Reschedule Period In Second',
  sendTime: 'Send Time',
  deadline: 'Deadline',
  sendOneTime: 'Only send one time',
  hour: 'hours',
  minute: 'minutes',

  success: 'Success',
  error: 'Error',
  sendNotificationSuccess: 'Send Notification Success',
  sendNotificationError: 'Send Notification Error',
  sendTimeMustBeforeDeadline: 'Send Time Must Before Deadline',
  confirmSCNote: 'Please send the code to the booker to check the information.',
}
