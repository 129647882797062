export default {
  create: {
    title: 'Create',
    createSuccesful: 'Create promotion user successful',
    createError: 'Error create promotion user',
  },
  fields: {
    no: 'No.',
    employee: 'Employees',
    employeeCode: 'Employee code',
    source: 'Source',
    promoCode: 'Promo code',
    maxApply: 'Max apply',
    promoCodeApplied: 'Promocode applied',
    status: 'Status',
    created: 'Created at',
    updated: 'Updated at',
  },
  placeholder: {
    employeeCode: 'Enter employee code: EMP1000220, EMP1000001, ...',
    source: 'Select source',
    promoCode: 'Enter promo code',
    maxApply: 'Enter max apply',
  },
  list: {
    title: 'Promotion User List',
    fetchError: 'Error fetch promotion user list',
  },
  detail: {
    title: 'Promotion User Detail',
    editSuccessful: 'Update promotion user successful',
    editError: 'Error update promotion user',
  },
}
