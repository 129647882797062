export default {
  HAN: 'Hanoi',
  SGN: 'Ho Chi Minh City',
  DAD: 'Da Nang',
  PQC: 'Phu Quoc',
  CXR: 'Nha Trang',
  BMV: 'Buon Ma Thuot',
  CAH: 'Ca Mau',
  VCA: 'Can Tho',
  VCL: 'Chu Lai',
  VCS: 'Con Dao',
  DLI: 'Da Lat',
  DIN: 'Dien Bien',
  VDH: 'Dong Hoi',
  HPH: 'Hai Phong',
  HUI: 'Hue',
  PXU: 'Pleiku',
  UIH: 'Quy Nhon',
  VKG: 'Rach Gia',
  THD: 'Thanh Hoa',
  TBB: 'Tuy Hoa',
  VDO: 'Van Don',
  VII: 'Vinh',
}
