export default {
  // for invoice list
  title: 'Hoá Đơn',
  management: 'Quản Lý Hoá Đơn',
  list: 'Danh Sách Hoá Đơn',
  lists: {
    all: 'Tất cả hóa đơn',
    awaitingApproval: 'HĐ chờ duyệt',
    approved: 'HĐ chờ ký',
  },
  date: 'Kiểu ngày',
  releaseStatus: {
    title: 'Trạng thái phát hành',
    NEW: 'Chưa gửi CQT',
    SENT: 'Đã gửi CQT',
    CQT_ISSUED_CODE: 'Đã cấp mã',
    CQT_REJECT_ISSUE_CODE: 'Từ chối cấp mã',
  },
  signStatus: {
    title: 'Trạng thái ký',
    UNSIGNED: 'Chưa ký',
    SIGNED: 'Đã ký',
  },
  signModal: {
    title: 'Ký điện tử',
    signType: 'Hình thức ký',
    phSignType: 'Chọn hình thức ký',
    FILE: 'File mềm',
    USB: 'USB Token',
    invoiceChosen: 'Hoá đơn đã chọn',
    columns: {
      checkbox: '',
      organizationName: 'Tên tổ chức (Cơ quan chứng thực, cấp, công nhận chữ ký số, chữ kí điện tử)',
      serial: 'Số sê-ri chứng thư số thức tờ khai',
      startDate: 'Từ ngày',
      endDate: 'Đến ngày',
    },
  },
  sendMailStatus: {
    title: 'Trạng thái gửi email',
    WAITING: 'Chờ gửi email',
    SENT: 'Đã gửi email',
  },
  invoiceNo: 'Số hoá đơn',
  invoiceType: 'Loại hóa đơn',
  invoiceStatus: {
    title: 'Trạng thái hóa đơn',
    NEW: 'HĐ mới',
    SIGNED: 'HĐ đã ký',
    ADJUSTMENT: 'HĐ điều chỉnh',
    ADJUSTED: 'HĐ bị điều chỉnh',
    REPLACEMENT: 'HĐ thay thế',
    REPLACED: 'HĐ bị thay thế',
    CANCELLED: 'HĐ bị huỷ',
  },

  ADJUSTMENT_TYPE: {
    DECREASE_ADJUSTMENT: 'Điều chỉnh giảm',
    INCREASE_ADJUSTMENT: 'Điều chỉnh tăng',
    INFORMATIONAL_ADJUSTMENT: 'Điều chỉnh thông tin',
  },

  emailStatus: 'Trạng thái gửi email',
  selectionCount:
    'Chưa chọn hóa đơn | Một hóa đơn được chọn | Có {count} hóa đơn được chọn',
  sendByEmail: 'Gửi hoá đơn qua email',
  preview: 'Xem hoá đơn',
  deselectInvoices: 'Bỏ chọn hóa đơn',
  cqtData: 'Lỗi của cơ quan thuế',
  cqtDataCode: 'Mã lỗi của cơ quan thuế',

  // 'ph'acronym of 'placeholder'
  phSelectDateOption: 'Chọn kiểu ngày',
  phSelectDate: 'Chọn ngày',
  phInvoiceNo: 'Nhập vào số hoá đơn',
  phSelectInvoiceType: 'Chọn loại hóa đơn',
  phSelectInvoiceStatus: 'Chọn trạng thái hóa đơn',
  phSelectEmailStatus: 'Chọn trạng thái gửi email',
  phSearch: 'Tìm kiếm theo mã số thuế, tên đơn vị, tên người mua hàng',

  dateOptions: {
    creationDate: 'Ngày lập hoá đơn',
    releaseDate: 'Ngày phát hành hoá đơn',
  },
  invoiceTypes: {
    original: 'HĐ gốc',
    adjustment: 'HĐ điều chỉnh',
    replacement: 'HĐ thay thế',
    cancellation: 'HĐ huỷ bỏ',
    adjusted: 'HĐ bị điều chỉnh',
  },
  status: {
    draft: 'Nháp',
    awaitingApproval: 'Chờ duyệt',
    approved: 'Chờ Ký', // ~ 'Đã duyệt',
    refuseApproval: 'Từ chối duyệt',
    signed: 'Đã ký',
    refuseSignature: 'Từ chối ký',
    awaitingRelease: 'Chờ phát hành',
    released: 'Đã phát hành',
    refuseRelease: 'Từ chối phát hành',
  },
  emailStatusOptions: {
    all: 'Tất cả',
    sent: 'Đã gửi',
    notSent: 'Chưa gửi',
  },
  tbssStatus: {
    notSent: 'Chưa gửi',
  },
  columns: {
    checkbox: '',
    invoiceNumber: 'Số hóa đơn',
    issueDate: 'Ngày phát hành',
    customer: 'Khách hàng',
    total: 'Tổng tiền thanh toán',
    totalPreTax: 'Tổng trước thuế',
    tax: 'Thuế',
    collectionFee: 'Phí Thu Hộ',
    taxAuthorityCode: 'Mã của CQT',
    releasedInvCode: 'Mã của CQT',
    type: 'Loại hoá đơn',
    status: 'Trạng thái hoá đơn',
    emailStatus: 'Trạng thái gửi email',
    errNoti: 'Thông báo sai sót',
    created: 'Thời gian tạo',
    updated: 'Thời gian sửa đổi',
    actions: 'Thao tác',
    action: 'Thao tác',
    actionNoTitle: '',
    symbol: 'Mẫu số/ Ký hiệu',
    invNumber: 'Số hoá đơn',
    createdAt: 'Ngày lập',
    date: 'Ngày hoá đơn',
    buyerName: 'Khách hàng',
    buyerTaxCode: 'Mã số thuế',
    totalAmount: 'Tổng tiền',
    invoiceStatus: 'Trạng thái hoá đơn',
    releaseStatus: 'Trạng thái phát hành',
    sendMailStatus: 'Trạng thái gửi email',
    no: 'STT',
    name: 'Tên hàng hoá, dịch vụ',
    ticketNumber: 'Số vé',
    unit: 'Đơn vị tính',
    quantity: 'Số lượng',
    unitPrice: 'Số tiền trước thuế',
    vatRate: 'Thuế suất',
    vatAmount: 'Tiền thuế GTGT',
    amount: 'Thành tiền',
    tags: 'Nhãn',
    referenceCode: 'Mã tra cứu',
    paymentMethod: 'Hình thức thanh toán',
    buyerUnitName: 'Tên công ty',
    buyerUnitCode: 'Mã khách hàng',
    buyerAddress: 'Địa chỉ',
    buyerEmail: 'Email',
    buyerPhoneNumber: 'Số điện thoại',
    bankAccountNumber: 'TK ngân hàng',
    bankName: 'Ngân hàng',
    signStatus: 'Trạng thái ký hoá đơn',
  },
  buyer: {
    name: 'Tên khách hàng',
  },
  moreDropdown: {
    add: 'Tạo hoá đơn',
    export: 'Xuất hoá đơn ra excel',
  },
  msgCanNotSignInvoices: 'Vui lòng chỉ chọn hoá đơn Chờ ký để ký.',
  msgCanNotSendToApproveInvoices:
    'Vui lòng chỉ chọn hóa đơn trạng thái Nháp hoặc Từ chối duyệt để gửi duyệt.',
  msgCanNotApproveInvoices:
    'Vui lòng chỉ chọn hóa đơn trạng thái Chờ duyệt để duyệt.',
  msgCanNotDisapproveInvoices:
    'Vui lòng chỉ chọn hóa đơn trạng thái Chờ duyệt để từ chối.',
  msgCanNotRefuseSignInvoices:
    'Vui lòng chỉ chọn hoá đơn Chờ ký để từ chối ký.',
  msgCanNotSendEmailInvoices:
    'Để gửi email cho nhiều hóa đơn, Vui lòng không chọn loại hoá đơn Bị huỷ bỏ/ Bị điều chỉnh có trạng thái khác với Đã phát hành.',
  msgSendToApproveSuccess: 'Gửi để phê duyệt thành công',
  msgSendToApproveErr: 'Gửi để phê duyệt không thành công',
  msgApproveSuccess: 'Duyệt thành công',
  msgApproveErr: 'Duyệt không thành công',
  msgDisapproveSuccess: 'Từ chối duyệt thành công',
  msgDisapproveErr: 'Từ chối duyệt không thành công',
  msgRefuseSignSuccess: 'Từ chối ký thành công',
  msgRefuseSignErr: 'Từ chối ký không thành công',

  // for invoice detail
  detail: 'Chi Tiết Hóa Đơn',
  errFetchInvoice: 'Lỗi khi lấy dữ liệu hóa đơn',
  notFundInvoiceById_1:
    'Không có hóa đơn nào được tìm thấy với id này. Kiểm tra',
  notFundInvoiceById_2: 'để tìm kiếm hoá đơn khác.',
  btn: {
    preview: 'Xem Hóa đơn',
    update: 'Sửa hoá đơn',
    edit: 'Chỉnh sửa',
    cancelEdit: 'Huỷ chỉnh sửa',
    sign: 'Ký hoá đơn',
    sendCQT: 'Gửi CQT',
    refuseSign: 'Từ chối ký',
    cancelInvoice: 'Hủy bỏ HĐ',
    adjustment: 'Điều chỉnh HĐ',
    replacement: 'Lập HĐ thay thế',
    sendToApprove: 'Gửi duyệt',
    saveAndSendToApprove: 'Lưu và Gửi duyệt',
    saveAndSign: 'Lưu và Ký',
    approve: 'Duyệt',
    disapprove: 'Từ chối duyệt',
    export: 'Xuất Excel',
    viewInvoice: 'Xem Hoá đơn',
    sendEmail: 'Gửi Email',
    save: 'Lưu',
    exit: 'Thoát',
    addLine: 'Thêm dòng',
    clearAll: 'Xoá tất cả',
    signAndReleaseInvoice: 'Ký và Phát hành hoá đơn',
    releaseInvoice: 'Phát hành hoá đơn',
    adjustInvoice: 'Lập hoá đơn điều chỉnh',
    replaceInvoice: 'Lập hoá đơn thay thế',
    duplicateInvoice: 'Nhân bản hoá đơn',
    cancelInv: 'Huỷ hoá đơn',
    detail: 'Chi tiết hoá đơn',
    exportPDF: 'Xuất PDF',
  },
  info: 'Thông tin',
  taxCode: 'Mã số thuế',
  noSerial: 'Mẫu/ Ký hiệu',
  company: 'Tên đơn vị',
  invoiceDate: 'Ngày lập hóa đơn',
  issueDate: 'Ngày phát hành',
  guestName: 'Tên người mua hàng',
  paymentMethod: 'Phương thức thanh toán',
  cancelReason: 'Lý do hủy bỏ',
  note: 'Ghi chú',
  ticketType: 'Loại vé',

  history: {
    title: 'Lịch sử Hoá đơn',
    transmission: 'Lịch sử truyền nhận',
    actionUpdate: 'Lịch sử chỉnh sửa',
    columns: {
      no: 'STT',
      name: 'Tên thông điệp',
      time: 'Thời gian gửi/nhận',
      taxAuthorityCode: 'Mã của CQT',
      status: 'Trạng thái',
      content: 'Chi tiết truyền nhận',
      action: 'Hành động',
      createdAt: 'Thời gian',
      modifiedBy: 'Người thực hiện',
      newValue: 'Thông tin chỉnh sửa',
      oldValue: 'Thông tin trước chỉnh sửa',
    },
    action: {
      CREATE: 'Tạo hoá đơn',
      UPDATE: 'Chỉnh sửa hoá đơn',
      SIGN: 'Ký hoá đơn',
      SEND_TO_TAX_AUTHORITY: 'Phát hành hoá đơn',
      SEND_EMAIL: 'Gửi email',
      UPDATE_SIGNED: 'Ký hoá đơn bằng USB Token',
      CANCEL: 'Huỷ hoá đơn',
      REPLACE: 'Thay thế hoá đơn',
      ADJUST: 'Điều chỉnh hoá đơn',
    },
  },
  historyColumns: {
    orderNo: 'STT',
    date: 'Thời gian',
    action: 'Thao tác',
    amount: 'Thông tin',
    executor: 'Người thực hiện',
  },
  historyActions: {
    CREATE: 'Tạo mới',
    UPDATE: 'Cập nhật',
    REFUSE_TO_SIGN: 'Từ chối ký',
    SIGN: 'Ký',
    ADJUST: 'Lập HĐ điều chỉnh',
    REPLACE: 'Lập HĐ thay thế',
    CANCEL: 'Huỷ bỏ',
    SEND_MAIL: 'Gửi email',
  },
  goodsServices: 'Hàng hóa và dịch vụ',
  // 'gs' mean 'Goods and Services':
  gsColumns: {
    orderNo: 'STT',
    characteristic: 'Tính chất HHDV',
    description: 'Tên hàng hóa, dịch vụ',
    unit: 'Đơn vị tính',
    quantity: 'Số lượng',
    unitPrice: 'Đơn giá',
    amountPreTax: 'Số tiền trước thuế',
    VATrate: 'Thuế suất',
    VAT: 'Tiền thuế',
    amount: 'Thành tiền',
    action: 'Thao tác',
  },
  totalAmountPreTax: 'Tổng số tiền trước thuế',
  totalVAT: 'Tổng tiền thuế',
  fee: 'Phí',
  discount: 'Chiết khấu',
  collectionFee: 'Phí thu hộ',
  totalPayment: 'Tổng tiền thanh toán',

  // for create/update/adjust/replace invoice
  pageTitle: {
    add: 'Tạo mới hoá đơn',
    update: 'Cập nhật hóa đơn',
    adjust: 'Điều chỉnh hóa đơn',
    replace: 'Lập hóa đơn thay thế',
  },
  getInfo: 'Lấy thông tin',
  phTaxCode: 'Nhập vào mã số thuế',
  phCompany: 'Nhập vào tên đơn vị',
  phPaymentMethod: 'Chọn phương thức thanh toán',
  phGuestName: 'Tìm kiếm hoặc nhập vào tên người mua hàng',
  phNote: 'Nhập vào ghi chú',
  phAddress: 'Nhập vào địa chỉ',
  phCancelReason: 'Nhập vào lý do hủy bỏ',
  phReceiver: 'Nhập vào tên người nhận',
  filters: {
    from: 'Từ ngày',
    to: 'Đến ngày',
    agencyCode: 'Mã đại lý',
    referenceCode: 'Mã tra cứu',
    tags: 'Nhãn',
  },
  ph: {
    buyerName: 'Nhập tên khách hàng',
    customerName: 'Nhập tên khách hàng',
    invNumber: 'Nhập số hoá đơn',
    referenceCode: 'Nhập mã tra cứu',
    agencyCode: 'Nhập mã đại lý',
    bookingCode: 'Nhập mã đặt chỗ',
    ticketNumber: 'Nhập số vé',
  },
  autoSendEmail: 'Tự động gửi email',
  topRowContent: {
    ADJUST:
      'Điều chỉnh cho hoá đơn Mẫu số {templateNumber}, ký hiệu {symbol}, số {invNumber}, ngày {createdAt}',
    REPLACE:
      'Thay thế cho hoá đơn Mẫu số {templateNumber}, ký hiệu {symbol}, số {invNumber}, ngày {createdAt}',
  },
  paymentMethodOptions: {
    TM: 'Tiền mặt',
    CK: 'Chuyển khoản',
    CK_AND_TM: 'TM/CK',
  },
  ticketTypeOptions: {
    domestic: 'Vé nội địa',
    international: 'Vé quốc tế',
    mix: 'Vé nội địa & Vé quốc tế',
  },
  characteristicOptions: {
    goods: 'Hàng hóa',
    fee: 'Phí, lệ phí ',
    discount: 'Chiết khấu',
    description: 'Ghi chú, diễn giải',
  },
  addRow: 'Thêm hàng',
  popup: {
    sendEmailTitle: 'Gửi Email Cho Khách Hàng',
    selectSendEmailOption: 'Tùy Chọn Tự Động Gửi Email Sau Khi Ký Hóa Đơn',
    cancelInvoiceTitle: 'Hủy Bỏ Hoá Đơn',
    chooseTicketTitle: 'Chọn Vé',
  },
  phSendEmail: 'Có thể nhập nhiều email, mỗi email cách nhau bởi dấu ";"',
  // 'se' mean 'Send Email':
  seColumns: {
    orderNo: 'STT',
    receiver: 'Tên người nhận',
    recipientEmail: 'Email người nhận',
    cc: 'CC',
    bcc: 'BCC',
    invoiceNo: 'Số hoá đơn',
    serial: 'Mẫu/ Ký hiệu',
    totalPayment: 'Tổng tiền thanh toán',
    emailStatus: 'Trạng thái gửi email',
    preview: 'Xem hoá đơn',
  },
  customerCode: 'Mã khách hàng',
  customerName: 'Tên khách hàng',
  chooseTicket: 'Chọn vé',
  showTicketNumber: 'Hiển thị số vé/ mã đặt chỗ',
  continue: 'Tiếp tục',
  combineTickets: 'Gộp vé',
  notification: 'Thông báo',
  download: 'Tải về',
  print: 'In',
  copyToClipboard: 'Click để sao chép vào bộ nhớ',
  lookupInvoice: 'Tra cứu hóa đơn',
  lookupCode: 'Mã tra cứu',
  lookup: 'Tra cứu',
  msgNoLookupCode: 'Nhập vào Mã tra cứu dài 14 ký tự.',
  msgErrLookupInvoice:
    'Không tìm thấy hoá đơn. Vui lòng nhập vào mã tra cứu khác',
  msgErrChangeQuantityOfTickets:
    'Chỉ được sửa số lượng thành giá trị >=1 và <={quantity} là số lượng vé đã gộp',
  msgErrChangeTotal: 'Lỗi! Vui lòng hoàn thành bảng Hàng hóa và Dịch vụ trước.',
  msgSuccessChangeTotalCollectionFee: 'Chỉnh sửa Phí thu hộ thành công.',
  msgSuccessChangeTotalPayment:
    'Đã chỉnh sửa đơn giá (các) hàng hoá dịch vụ khớp với Tổng tiền thanh toán hiệu chỉnh.',
  msgErrCreateInvoice: 'Lỗi khi tạo hóa đơn.',
  msgErrCreateInvoiceWithBeKeptTickets:
    'Không tạo được hoá đơn vì {tickets} đang chờ duyệt xuất hoá đơn.',
  msgSuccessCreateInvoice: 'Tạo hóa đơn thành công.',
  msgErrUpdateInvoice: 'Lỗi khi cập nhật hóa đơn.',
  msgSuccessUpdateInvoice: 'Cập nhật hóa đơn thành công.',
  msgErrAdjustInvoice: 'Lỗi khi lập hóa đơn điều chỉnh.',
  msgSuccessAdjustInvoice: 'Lập hóa đơn điều chỉnh thành công.',
  msgErrReplaceInvoice: 'Lỗi khi lập hóa đơn thay thế.',
  msgSuccessReplaceInvoice: 'Lập hóa đơn thay thế thành công.',
  msgSuccessSaveButErrSendToApprove:
    'Lưu hoá đơn thành công nhưng Gửi duyệt không thành công.',
  msgSuccessSaveAndSendToApprove: 'Lưu và Gửi duyệt thành công.',
  msgErrSignInvoice: 'Lỗi ký hoá đơn.',
  msgSuccessSignInvoice: 'Ký hóa đơn thành công.',
  msgErrCombineTicketsNotSameTrips: 'Chỉ chọn các vé cùng hành trình.',

  // CREATE
  create: {
    title: 'Lập hoá đơn',
    info: {
      title: 'Thông tin xuất hoá đơn',
      // row1
      sellerInfo: 'Thông tin bên bán',
      phSellerInfo: 'Chọn đại lý xuất hoá đơn',
      symbol: 'Ký hiệu',
      phSymbol: 'Ký hiệu HĐ',
      series: 'Mẫu số hoá đơn',
      phSeries: 'Mẫu số hoá đơn',
      invNumber: 'Số hoá đơn',
      phInvNumber: 'Số hoá đơn',
      taxAuthorityCode: 'Mã CQT',
      // row2
      buyerType: 'Loại khách hàng',
      KL: 'Khách lẻ',
      CTV: 'CTV',
      phBuyerType: 'KL/CTV',
      buyerName: 'Tên người mua',
      phBuyerName: 'Chọn/Nhập tên...',
      buyerCompany: 'Tên công ty',
      phBuyerCompany: 'Nhập tên công ty',
      buyerTaxCode: 'MST',
      findByTaxCode: 'Tìm theo MST',
      buyerAddress: 'Địa chỉ',
      phBuyerAddress: 'Nhập Địa chỉ',
      buyerEmail: 'Email',
      phBuyerEmail: 'Nhập Email',
      saveBuyerInfo: 'Lưu thông tin KH',
      // row3
      invoiceType: 'Loại hoá đơn',
      phInvoiceType: 'Chọn loại hoá đơn',
      invoiceTemplate: 'Mẫu hoá đơn',
      phInvoiceTemplate: 'Chọn mẫu hoá đơn',
      paymentMethod: 'Hình thức thanh toán',
      phPaymentMethod: 'TM/CK',
      createdAt: 'Ngày lập',
      lookUpCode: 'Mã tra cứu',
      transactionCode: 'Mã giao dịch',
      // row4
      invoiceStatus: 'Trạng thái hoá đơn',
      assignTag: 'Gắn nhãn',
      phAssignTag: 'Nhập nhãn và nhấn Enter ⏎ để thêm mới',
    },
    selectTicket: {
      title: 'Chọn vé',
      ticketNumber: 'Số vé',
      phTicketNumber: 'Nhập số vé...',
      ticketType: 'Loại vé',
      phTicketType: 'TKT/EMD',
      airline: 'Hãng HK',
      passengerName: 'Tên hành khách',
      bookingCode: 'Mã ĐC/ĐH',
      phBookingCode: 'Nhập mã ĐC/Đơn hàng...',
      agencyCode: 'Mã đại lý',
      phAgencyCode: 'Chọn Mã đại lý',
      search: 'Tìm kiếm',
      clearFilter: 'Xoá bộ lọc',
      domesticTicket: 'Vé Quốc nội',
      inboundTicket: 'Vé Quốc tế',
      trainTicket: 'Vé tàu',
      viewDetails: 'Xem chi tiết',
      columns: {
        checkbox: '',
        bookingCode: 'Mã ĐC',
        paxName: 'Tên HK',
        ticketNumber: 'Số vé',
        trips: 'HT',
        airline: 'Hãng',
        agencyCode: 'Mã ĐL',
        ticketType: 'LV',
        amount: 'Tiền TT',
        no: 'STT',
        created: 'TG tạo',
        updated: 'TG sửa đổi',
        // airline: 'Hãng',
        source: 'HTĐV',
        // bookingCode: 'Mã ĐC',
        // paxName: 'Tên HK',
        paxId: 'Mã HK',
        paxType: 'Loại HK',
        // ticketNumber: 'Số vé',
        paymentCode: 'Mã TT',
        // ticketType: 'Loại vé',
        flightType: 'Loại HT',
        // trips: 'Hành trình',
        flightDates: 'Ngày bay',
        bookingClass: 'Hạng ĐC',
        fareBasisCode: 'Loại giá vé',
        booker: 'Người đặt',
        issuer: 'Người xuất',
        issueDate: 'Ngày TT',
        bookingDate: 'Ngày đặt',
        agency: 'Đại lý',
        signBook: 'TK đăng ký',
        currency: 'Loại tiền',
        priceTicket: 'Giá vé net',
        vat: 'Vat',
        otherTax: 'Thuế phí khác',
        tax: 'Phí thu hộ',
        fee: 'Phí',
        feeIn: 'Phí xuất vé F1',
        feeOut: 'Phí xuất vé F2',
        feeService: 'Phí dịch vụ',
        commissionPrepaid: 'HH đã trả',
        commissionPrepaidIn: 'HH đã được trả',
        commissionPrepaidOut: 'HH đã chi',
        commission: 'HH hãng',
        unpaidCommission: 'HH trả sau',
        unpaidCommissionIn: 'HH trả sau được nhận',
        unpaidCommissionOut: 'HH trả sau phải trả',
        totalPayment: 'Tổng tiền',
        tradeCreditors: 'Tổng phải trả',
        receivables: 'Tiền TT', // 'Tổng phải thu',
        balanceAdjustment: 'Số dư điều chỉnh',
        accountsPayable: 'Công nợ',
        profit: 'Lợi nhuận',
        createdAt: 'TG tạo',
        updatedAt: 'TG cập nhật',
        collectionFee: 'Phí thu hộ',
      },
    },
    detail: {
      title: 'Chi tiết hoá đơn',
      note: 'Ghi chú',
      phNote: 'Nhập ghi chú',
      totalGoodsAmount: 'Tổng tiền hàng',
      collectionFee: 'Phí thu hộ',
      totalAmount: 'Tổng tiền thanh toán',
      invoiceContent: 'Nội dung hoá đơn',
      showTicketNumber: 'Hiển thị số vé trong hoá đơn',
      pleaseChooseTickets: 'Vui lòng chọn vé',
      DomesticTicket: 'Vé máy bay nội địa',
      InboundTicket: 'Vé máy bay quốc tế',
      OtherTicket: 'Khác',
      adjustReason: 'Lí do điều chỉnh',
      adjustmentType: 'Loại điều chỉnh',
      phAdjustmentType: 'Chọn loại điều chỉnh',
      phAdjustReason: 'Nhập lý do điều chỉnh',
      adjustAutoCalculate: 'Tự động tính toán giá trị điều chỉnh',
      phName: 'Vui lòng nhập',
    },
    payment: {
      title: 'Tổng tiền thanh toán',
      addDocuments: 'Thêm chứng từ',
      totalAmount: 'Tổng tiền thanh toán',
      totalAmountNeedToPay: 'Cần thanh toán thêm',
      phTotalAmountNeedToPay: 'Số tiền chênh',
      agencyIssue: 'Đại lý có',
      agencyReceive: 'Đại lý nợ',
    },
  },

  msg: {
    createSuccess: 'Tạo hoá đơn thành công',
    createError: 'Lỗi tạo hoá đơn',
    sendEmailSuccess: 'Gửi email thành công',
    sendEmailError: 'Lỗi Gửi email',
    releaseInvoiceSuccess: 'Phát hành hoá đơn thành công',
    releaseInvoiceError: 'Lỗi Phát hành hoá đơn',
    signSuccess: 'Ký hoá đơn thành công',
    signError: 'Lỗi ký hoá đơn',
    signAndReleaseSuccess: 'Ký và phát hành hoá đơn thành công',
    signAndReleaseError: 'Lỗi ký và phát hành hoá đơn',
    exportSuccess: 'Xuất excel thành công',
    exportError: 'Lỗi xuất excel',
    updateInvoiceSuccess: 'Chỉnh sửa hoá đơn thành công',
    updateInvoiceError: 'Lỗi chỉnh sửa hoá đơn',
    cancelInvoiceSuccess: 'Huỷ hoá đơn thành công',
    cancelInvoiceError: 'Lỗi huỷ hoá đơn',
    replaceInvoiceSuccess: 'Thay thế hoá đơn thành công',
    replaceInvoiceError: 'Lỗi thay thế hoá đơn',
    adjustInvoiceSuccess: 'Điều chỉnh hoá đơn thành công',
    adjustInvoiceError: 'Lỗi điều chỉnh hoá đơn',
    duplicateInvoiceSuccess: 'Nhân bản hoá đơn thành công',
    duplicateInvoiceError: 'Lỗi nhân bản hoá đơn',
    companyNotFound: 'Không tìm thấy thông tin doanh nghiệp đăng ký xuất hoá đơn!',
    companyNotFoundOrDeactivated: 'Không tìm thấy thông tin doanh nghiệp đăng ký xuất hoá đơn hoặc đã tắt hoạt động!',
    templateNotFound: 'Không tìm thấy mẫu hoá đơn',
    invalidInvoiceItems: 'Nội dung hoá đơn không được để trống',
    saveCustomerError: 'Lỗi lưu thông tin khách hàng',
    saveCustomerSuccess: 'Lưu thông tin khách hàng thành công',
    confirmDuplicate: 'Bạn chắc chắn muốn nhân bản hoá đơn?',
    noInvoiceSelected: 'Vui lòng chọn hoá đơn',
    differenceGenerateInvoiceNumberOnCreationError: 'Hóa đơn không thuộc cấu hình sinh số hiện tại. Vui lòng chuyển đổi cấu hình và thực hiện lại thao tác!',
    WRONG_STATUS: 'Trạng thái không thế phát hành',
    DIFFERENCE_GENERATE_INVOICE_NUMBER_ON_CREATION: 'Hóa đơn không thuộc cấu hình sinh số hiện tại',
  },

  invalidInvoiceAlert: 'Lỗi tải thông tin hoá đơn.',

  cancel: {
    title: 'Huỷ hoá đơn',
    cancelDate: 'Ngày huỷ',
    reason: 'Lý do huỷ',
    sendCancellationNotification: 'Gửi thông báo hủy hóa đơn cho khách hàng',
    receiverName: 'Tên người nhận',
    email: 'Email',
    submit: 'Xác nhận',
    add: 'Thêm',
    addCc: 'Thêm cc',
    addBcc: 'Thêm bcc',
    no: '#',
    symbol: 'Mẫu số/ Ký hiệu',
    invNumber: 'Số hoá đơn',
    createdAt: 'Ngày lập',
    buyerName: 'Khách hàng',
    releasedInvCode: 'Mã của CQT',
    totalAmount: 'Tổng tiền',
    tbssStatus: 'Trạng thái gửi TBSS',
    cc: 'CC',
    bcc: 'BCC',
    invoiceCannotCancel: 'Các số hoá đơn không thể huỷ',
    ccBccJoinCommaPlaceholder: 'Phân cách bằng dấu ,',
  },

  releaseBatch: {
    title: 'Phát hành hóa đơn theo lô',
    invoiceCannotRelease: 'Hoá đơn không thể phát hành',
    invoiceChosen: 'Danh sách hoá đơn đã chọn',
    processingError: 'Không thể phát hành hoá đơn theo lô do đang có lô hoá đơn phát hành khác đang thực thi. Vui lòng đợi và thử lại!',
  },

  setDefault: 'Mặc định',
  showAll: 'Hiển thị tất cả cột',

  invoiceTickets: {
    title: 'Vé ngoài hệ thống',
    shortTitle: 'Vé ngoài',
    fields: {
      no: '#',
      from: 'Từ ngày',
      to: 'Đến ngày',
      checkbox: '',
      createdAt: 'Ngày tạo',
      bookingCode: 'Mã đặt chỗ',
      ticketNumber: 'Số vé',
      customerName: 'Tên khách hàng',
      flightTrip: 'Hành trình',
      type: 'Phân loại',
      invoiceTicketType: 'Loại vé',
      totalAmount: 'Tiền thanh toán',
      valueAddedTax: 'VAT',
      collectionFee: 'Phí thu hộ',
      actions: 'Thao tác',
      flightDate: 'Ngày',
      isInvoiced: 'Trạng thái lập hoá đơn',
      message: 'Lỗi',
    },
    type: {
      AIRLINE_TICKET: 'Vé máy bay',
      TRAIN_TICKET: 'Vé tàu',
      TOUR_TICKET: 'Vé tour',
      ALL: 'Tất cả',
      OTHER: 'Khác',
    },
    isInvoicedFilter: {
      ALL: 'Tất cả',
      INVOICED: 'Đã lập hoá đơn',
      UNINVOICED: 'Chưa lập hoá đơn',
    },
    create: {
      title: 'Thêm vé ngoài hệ thống',
    },
    detail: {
      title: 'Chi tiết vé ngoài hệ thống',
    },
    btn: {
      delete: 'Xoá vé',
      view: 'Xem chi tiết vé',
      viewInvoice: 'Xem hoá đơn tương ứng',
      edit: 'Sửa vé',
      back: 'Trở lại',
      save: 'Lưu',
      create: 'Thêm',
      cancelEdit: 'Huỷ chỉnh sửa',
      import: 'Nhập từ excel',
    },
    import: {
      downloadSampleConfirm: 'Tải xuống tệp mẫu?',
    },
  },

  taxTypes: {
    ZERO_PERCENT: '0%',
    FIVE_PERCENT: '5%',
    EIGHT_PERCENT: '8%',
    TEN_PERCENT: '10%',
    NON_TAXABLE: 'Không chịu thuế',
    NON_DECLARED: 'Không kê khai nộp thuế',
    OTHER: 'Khác',
  },
  tabs: {
    saleReport: 'Báo cáo bán',
    invoiceTickets: 'Vé ngoài hệ thống',
  },
}
