export default {
  title: 'Schedule Calendar',
  titleList: 'Schedule Calendar List',
  name: 'Name',
  scheduleCalendarTimes: 'Schedule Calendar Times',
  note: 'Note',
  startTime: 'Start Time',
  endTime: 'End Time',

  createScheduleCalendarError: 'Create schedule calendar error',
  createScheduleCalendarSuccess: 'Create schedule calendar success',
  editScheduleCalendarSuccess: 'Edit Schedule Calendar Success',
  deleteScheduleCalendarSuccess: 'Delete Schedule Calendar Success',

  addMore: 'Add More',

  create: {
    title: 'Create',
    cancel: 'Cancel',
  },

  edit: {
    title: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
  },
}
