export default {
  // for error notification list
  close: 'Đóng',
  management: 'Quản Lý Thông Báo Hoá Đơn Sai Sót',
  list: 'Danh Sách Thông Báo Sai Sót',
  create: 'Tạo mới',
  description: 'Mô tả',
  errorNotiNo: 'Số thông báo của CQT',
  errorNotiDate: 'Ngày thông báo của CQT',
  errorNotiType: 'Loại thông báo',
  notiStatus: 'Trạng thái',
  selectionCount:
    'Chưa chọn thông báo sai sót | Một thông báo sai sót được chọn | Có {count} thông báo sai sót được chọn',
  preview: 'Xem thông báo sai sót',

  // 'ph'acronym of 'placeholder'
  phSelectDateOption: 'Chọn kiểu ngày',
  phSelectDate: 'Chọn ngày',
  phErrorNotiNo: 'Nhập số thông báo',
  phSelectNotiType: 'Chọn loại thông báo',
  phSelectStatus: 'Chọn trạng thái',
  phSelectEmailStatus: 'Chọn trạng thái gửi email',
  phSearch: 'Tìm kiếm theo số hóa đơn, số thông báo của CQT',

  dateOptions: {
    creationDate: 'Ngày lập thông báo',
    issueDate: 'Ngày phát hành thông báo',
  },
  type: {
    nnt: 'NNT',
    cqt: 'CQT',
  },
  status: {
    draft: 'Nháp',
    waitingToSign: 'Chờ ký',
    sign: 'Đã ký',
    refuseToSign: 'Từ chối ký',
    waitingToIssue: 'Chờ CQT tiếp nhận',
    issue: 'CQT đã tiếp nhận',
    refuseToIssue: 'CQT từ chối',
  },
  columns: {
    checkbox: '',
    no: 'Số thông báo',
    type: 'Loại thông báo',
    invoices: 'Số lượng hóa đơn',
    taAcceptNo: 'Số thông báo (CQT chấp nhận)',
    taAcceptDate: 'Ngày thông báo (CQT chấp nhận)',
    status: 'Trạng thái',
    created: 'Thời gian tạo',
    updated: 'Thời gian cập nhật',
    actions: 'Thao tác',
  },
  moreDropdown: {
    add: 'Tạo thông báo sai sót',
    export: 'Xuất thông báo sai sót ra excel',
  },
  msgCanNotSignInvoices: 'Vui lòng chỉ chọn hoá đơn Chờ ký để ký.',

  // for error notification detail
  errFetchNoti: 'Lỗi khi lấy dữ liệu thông báo sai sót',
  notFundNotiById_1:
    'Không có thông báo sai sót nào được tìm thấy với id này. Kiểm tra',
  notFundNotiById_2: 'để tìm kiếm thông báo khác.',
  btn: {
    preview: 'Xem trước',
    previewInDetailPage: 'Xem thông báo sai sót',
    delete: 'Xóa bỏ',
    sendToSign: 'Gửi ký',
    cancel: 'Hủy bỏ',
    save: 'Lưu',
    saveAndSend: 'Lưu & Gửi ký',
  },
  info: 'Thông tin',
  taxCode: 'Mã số thuế',
  noSerial: 'Mẫu/ Ký hiệu',
  company: 'Tên đơn vị',
  invoiceDate: 'Ngày lập hóa đơn',
  issueDate: 'Ngày phát hành',
  guestName: 'Tên người mua hàng',
  paymentMethod: 'Phương thức thanh toán',
  cancelReason: 'Lý do hủy bỏ',
  note: 'Ghi chú',
  ticketType: 'Loại vé',

  history: 'Lịch sử thao tác',
  historyColumns: {
    orderNo: 'No.',
    date: 'Thời gian',
    action: 'Thao tác',
    executor: 'Người thực hiện',
  },
  historyActions: {
    CREATE: 'Tạo mới ',
    UPDATE: 'Cập nhật',
    WAITING_TO_APPROVE: 'Chờ duyệt',
    SIGN: 'Ký',
    REFUSE_TO_SIGN: 'Từ chối ký',
    WAITING_TO_ISSUE: 'Chờ phát hành',
  },
  goodsServices: 'Hàng hóa và dịch vụ',
  // 'gs' mean 'Goods and Services':
  gsColumns: {
    orderNo: 'No.',
    invoiceNumber: 'Số hóa đơn',
    serial: 'Mẫ số/Ký hiệu',
    issueDate: 'Ngày phát hành',
    taCode: 'Mã CQT  ',
    invoiceType: 'Hủy bỏ/Điều chỉnh/Thay thế/Giải trình',
    reason: 'Lý do',
    action: 'Thao tác',
  },
  totalAmountPreTax: 'Tổng số tiền trước thuế',
  totalVAT: 'Tổng tiền thuế',
  fee: 'Phí',
  discount: 'Chiết khấu',
  collectionFee: 'Phí thu hộ',
  totalPayment: 'Tổng tiền thanh toán',

  // for create/update/adjust/replace invoice
  pageTitle: {
    add: 'Tạo Mới Thông Báo Sai Sót',
    update: 'Cập Nhật Thông Báo Sai Sót',
    detail: 'Chi Tiết Thông Báo Sai Sót',
  },
  getInfo: 'Lấy thông tin',
  phTaxCode: 'Nhập vào mã số thuế',
  phCompany: 'Nhập vào tên đơn vị',
  phPaymentMethod: 'Chọn phương thức thanh toán',
  phGuestName: 'Tìm kiếm hoặc nhập vào tên người mua hàng',
  phNote: 'Nhập vào ghi chú',
  phAddress: 'Nhập vào địa chỉ',
  phCancelReason: 'Nhập vào lý do hủy bỏ',
  phReceiver: 'Nhập vào tên người nhận',
  autoSendEmail: 'Tự động gửi email',
  addRow: 'Thêm hàng',
  popup: {
    sendEmailTitle: 'Gửi Email Cho Khách Hàng',
    selectSendEmailOption: 'Tùy Chọn Tự Động Gửi Email Sau Khi Ký Hóa Đơn',
    cancelInvoiceTitle: 'Hủy Bỏ Hoá Đơn',
    chooseTicketTitle: 'Chọn Vé',
  },
  phSendEmail: 'Có thể nhập nhiều email, mỗi email cách nhau bởi dấu ";"',
  chooseTicket: 'Chọn vé',
  continue: 'Tiếp tục',
  combineTickets: 'Gộp vé',
  notification: 'Thông báo',
}
