export default {
  titleRepicient: 'Topup request',
  titleSender: 'Topup',
  management: 'Top Up Management',
  list: 'Top Up List',
  paymentStatus: 'Payment status',
  amount: 'Amount',
  paymentAmount: 'Payment amount',
  senderId: 'Sender ID',
  senderName: 'Sender name',
  entity: 'Entity',
  senderEntity: 'Sender entity',
  recipientId: 'Recipient ID',
  recipientEntity: 'Recipient entity',
  bankAccountName: 'Bank account name',
  bankAccountNo: 'Bank account number',
  note: 'Note',
  evidence: 'Evidence',
  uploadEvidence: 'Upload evidence image',
  placeholderChooseImage: 'Choose a image or drop it here...',
  placeholderDropImage: 'Drop image here...',
  evidenceUrl: 'Evidence URL',
  bankCode: 'Bank code',
  status: {
    pendingPayment: 'Pending',
    done: 'Done',
    canceled: 'Canceled',
    confirming: 'Confirming',
  },
  startDate: 'Start date',
  endDate: 'End date',
  bankAcc: 'Bank accounts',
  placeholderSelectDate: 'Please select a date',
  placeholderSearch: 'Search text by code, sender name, bank name',
  moreDropdownTitle: 'More',
  tableColumns: {
    checkbox: '',
    paymentCode: 'Payment code',
    senderInfo: 'Sender info',
    created: 'Created',
    updated: 'Updated',
    confirmedBy: 'Confirmed by',
    paymentStatus: 'Status',
    paymentInfo: 'Payment info',
    actions: 'Actions',
  },
  currency: 'VND',
  detail: 'Top Up Detail',
  tabInfo: 'Top Up Information',
  confirm: 'Confirm',
  confirmManual: 'Confirm top up manually',
  errFetchData_1: 'Error fetching top up data',
  errFetchData_2: "No top up found with this customer's id. Check",
  errFetchData_3: 'for other top up.',

  createTopup: 'Create topup',
  createTopupForChild: 'Create topup for agency',
  tooltip: {
    allowTopup: 'Only signed agency can create topup, please contact Booker for assistance',
  },
  confirmAutoPayment: 'Confirm auto payment',
  at: 'at',
  sender: 'Sender',
  recipient: 'Recipient',
  placeholderSender: 'Please select sender',
  pleaseTopupMinAmount: 'Please top up a minimum of {minAmount} VND',
  topupInfo: 'Top up information',
  parentAgencyNotHaveBankAccount: 'The parent agency has not set up a receiving account. Please contact your parent agency.',
  createQrSuccess: 'Top up request created successfully. Please scan the QR code to top up.',
  history: 'Top up history',
  noHistoryRecord: 'No history records',
}
