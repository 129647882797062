export default {
  list: {
    title: 'Danh sách đặt chỗ tàu',
    columns: {
      no: 'STT',
      bookingCode: 'Mã đặt chỗ',
      ticketId: 'Số vé',
      customerName: 'Khách hàng',
      bookingInfos: 'Thông tin vé',
      totalPrice: 'Giá net',
      totalPriceTicket: 'Giá bán',
      createdAt: 'Ngày đặt',
      createdBy: 'Người đặt',
      status: 'Trạng thái',
      actions: 'Thao tác',
    },
    and: 'và',
    otherTicket: 'vé khác',
  },
  filter: {
    statusPlaceholder: 'Chọn trạng thái',
    tripDatePlaceholder: 'Ngày khởi hành',
    bookDatePlaceholder: 'Ngày đặt vé',
    bookingCodePlaceholder: 'Mã đặt chỗ',
    searchTextPlaceholder: 'Tìm kiếm tên mã đặt chỗ, khách hàng, tên hành khách...',
    clearFilter: 'Xoá bộ lọc',
  },
  status: {
    title: 'Trạng thái',
    CANCEL: 'Đã hủy',
    EXPIRED: 'Quá hạn',
    HOLD: 'Giữ chỗ',
    PAID: 'Đã thanh toán',
    PROCESSING: 'Đang thực hiện',
    ALL: 'Tất cả',
  },
  detail: {
    contact: {
      title: 'Thông tin liên hệ',
      fullName: 'Họ và tên',
      identifyNumber: 'Số CCCD/Hộ chiếu',
      phoneNumber: 'Số điện thoại',
      email: 'Email',
    },
    bookingInfo: {
      title: 'Thông tin chi tiết vé',
      columns: {
        no: '#',
        fullName: 'Họ tên',
        identifyNumber: 'Số CCCD/Hộ chiếu',
        ticketId: 'Số vé',
        paxType: 'Loại khách',
        seatType: 'Loại ghế',
        trip: 'Hành trình',
        ticketInfo: 'Thông tin vé',
        departureTime: 'Giờ đi',
        arrivalTime: 'Giờ đến',
        totalPrice: 'Tổng tiền',
        status: 'Trạng thái',
        actions: 'Thao tác',
      },
      showPriceTicket: 'Hiện phí dịch vụ',
      hidePriceTicket: 'Ẩn phí dịch vụ',
      showProfit: 'Hiện lợi nhuận',
      hideProfit: 'Ẩn lợi nhuận',
      downloadTicket: 'Tải vé',
    },
    tripInfo: {
      title: 'Thông tin hành trình',
      trip: 'Hành trình',
      train: 'Tàu',
    },
    timeHold: 'Thời gian giữ chỗ',
    createdAt: 'Thời gian đặt vé',
    auditLog: {
      title: 'Lịch sử thao tác',
      columns: {
        orderNo: 'STT',
        date: 'Thời gian',
        action: 'Thao tác',
        amount: 'Thông tin',
        executor: 'Người thực hiện',
      },
    },
  },
  btn: {
    viewDetail: 'Xem chi tiết',
  },

  // search
  search: {
    searchTrain: 'ĐẶT VÉ TÀU',
    departureDate: 'Ngày đi',
    returnDate: 'Ngày về',
    search: 'Tìm kiếm',
    selectDate: 'Chọn ngày (*)',
    loadingData: 'Đang tải dữ liệu',

    result: {
      departureDate: 'Ngày đi',
      returnDate: 'Ngày về',

      // index
      searching: 'Đang tìm kiếm ...',
      noTrainFound: 'Không tìm thấy chuyến tàu trên hành trình này !',
      clearAllTickets: 'Xoá tất cả vé',
      clearSelectedTickets: 'Xoá tất cả vé đã chọn',
      enterDiscountCode: 'Nhập mã giảm giá',
      apply: 'Áp dụng',
      totalAmount: 'Tổng tiền',
      termsAndConditions: 'Tôi đã đọc kỹ và đồng ý tuân thủ tất cả các quy định mua vé trực tuyến, các chương trình khuyến mại của Airdata và chịu trách nhiệm về tính xác thực của các thông tin trên.',
      goBack: 'Quay lại',
      bookTicket: 'Đặt vé',
      note: 'Lưu ý',
      pleaseSearchAgain: 'Vui lòng tìm kiếm lại hành trình !',
      bookingSuccessful: 'Đặt vé tàu thành công !',
      bookingError: 'Lỗi đặt vé !',
      selectTicketAndEnterInfo: 'Vui lòng chọn vé và điền thông tin !',
      selectReturnTicketAndEnterInfo: 'Vui lòng chọn vé và điền thông tin chiều về !',
      ticketExpired: 'Vui lòng huỷ những vé đã quá thời gian giữ chỗ !',
      enterPassengerInfo: 'Vui lòng điền đầy đủ thông tin hành khách !',
      acceptTermsAndConditions: 'Vui lòng chọn xác nhận điều khoản và quy định đặt vé !',
      selectTicket: 'Vui lòng chọn vé !',
      confirmInfo: 'Xác nhận thông tin',

      // -
      outbound: 'Chiều đi:',
      inbound: 'Chiều về:',
      viewPromotions: 'Xem khuyến mãi',
      departureTime: 'Thời gian đi',
      arrivalTime: 'Thời gian đến',
      booked: 'Đã đặt',
      seat: 'chỗ',
      available: 'Còn trống',
      trainPromotions: 'Khuyến mãi tàu',
      noPromotions: 'Không có khuyến mãi',
      searchError: 'Lỗi tìm kiếm thông tin chuyến tàu',

      // seat, bed
      carriageNumber: 'Toa số',
      pleaseSelectCarriage: 'Vui lòng chọn toa tàu !',
      availableSeats: 'Chỗ trống',
      price: 'Giá',
      priceNote: '(Chưa bao gồm khuyến mãi, phí dịch vụ)',
      selectedSeat: 'Chỗ đã chọn',
      noPriceSeat: 'Chỗ không có giá',
      soldSeat: 'Chỗ đã bán',

      // paxInfo
      ticketCartInfo: 'Thông tin giỏ vé',
      fillCompleteInfo: 'Vui lòng điền đầy đủ thông tin bao gồm: Họ tên và CCCD, hộ chiếu, GPLX hoặc ',
      fillCompleteInfo2: 'ngày tháng năm sinh nếu là trẻ em',
      fillCompleteInfo3: 'thẻ sinh viên nếu là sinh viên.',
      or: 'hoặc',
      noTicketSelected: 'Chưa chọn vé',
      passenger: 'Hành khách',
      passengerName: 'Tên hành khách',
      idPassportLicense: 'CCCD / Hộ chiếu / GPLX',
      roundTripOutbound: 'Khứ hồi chiều đi',
      roundTripInbound: 'Khứ hồi chiều về',
      oneWayOutbound: 'Vé lẻ chiều đi',
      oneWayInbound: 'Vé lẻ chiều về',
      ticketPrice: 'Giá vé:',
      serviceFee: 'Phí dịch vụ:',
      discountEligible: 'Giảm đối tượng:',
      promoNote: 'Lưu ý: Đây là vé áp dụng chương trình khuyến mãi, đề nghị quý khách đọc kỹ',

      fullName: 'Họ và tên',
      idOrPassport: 'Số CCCD/Hộ chiếu',

      unaccompaniedChild: 'Trẻ em đi tàu một mình',
      unaccompaniedChildNotice: 'Theo quy định của Tổng Công ty ĐSVN, trẻ em không được đi tàu khi không có người lớn đi kèm, vui lòng nhập mã vé người lớn đi kèm để tiếp tục.',
      adultTicketNumber: 'Số vé người lớn',
      childRoundTripNotice: 'Đối với hành trình khứ hồi của trẻ em đi một mình, vui lòng thực hiện tìm kiếm và đặt vé theo từng chặng một chiều.',
      confirm: 'Xác nhận',

      dob: 'Ngày tháng năm sinh',
      noTicketForUnder6: 'Trẻ em dưới 6 tuổi không cần phải mua vé.',
      childDiscount: 'Trẻ em từ 6 tuổi đến 10 tuổi được mua vé trẻ em.',

      seniorDiscount: 'Người cao tuổi (người từ 60 tuổi trở lên) được hưởng chính sách giảm giá theo quy định của Tổng công ty Đường sắt Việt Nam.',
      invalidDiscountAge: 'Không đúng tuổi hưởng ưu đãi!',
      childUnder6NoTicket: 'Trẻ em dưới 6 tuổi không cần mua vé nếu sử dụng chung chỗ của người lớn đi cùng. Bạn muốn tiếp tục mua vé riêng cho trẻ em này?',
      feeService: 'Phí dịch vụ',
      compartment: 'Khoang',

      columns: {
        seatInfo: 'Thông tin chỗ',
        ticketPrice: 'Chi tiết giá',
        discountPassenger: 'Giảm đối tượng',
        addonsDetails: ' Dịch vụ',
        discountPromotion: 'Khuyến mãi',
        discountService: 'Khuyến mãi dịch vụ',
        insurancePrice: 'Bảo hiểm',
        totalPrice: 'Thành tiền',

        coachName: 'Toa số',
        seatCode: 'Loại chỗ',
        seatName: 'Tên loại chỗ',
        seatAvailable: 'Số chỗ',
        ticketSelect: 'Số lượng vé',
      },

      cart: {
        ticketCart: 'Giỏ vé',
        noTicketSelected: 'Chưa chọn vé',
        coach: 'Toa',
        seat: 'Chỗ',
      },
    },
  },

  // common
  bookingCode: 'Mã đặt chỗ',
  coach: 'Toa',
  seatNumber: 'Số ghế',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  lookUp: 'Tra cứu',
  bookerInfo: 'Thông tin người đặt vé',
  note: {
    carriageSelected: 'Toa đang chọn',
    carriageAvailable: 'Toa còn vé',
    carriageUnavailable: 'Toa hết vé',
    selected: 'Chỗ đang chọn',
    available: 'Chỗ trống',
    unavailable: 'Chỗ đã bán, không bán',
  },
  msg: {
    retrieveError: 'Lỗi tra cứu thông tin vé',
  },
}
