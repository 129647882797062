export default {
  chooseLanguage: 'Chọn ngôn ngữ:',
  welcomeTo: 'Chào mừng đến với',
  signInText: 'Vui lòng đăng nhập vào tài khoản của bạn',
  Username: 'Tên đăng nhập',
  Password: 'Mật khẩu',
  LogIn: 'Đăng nhập',
  keepLogin: 'Lưu đăng nhập',
  forgotPassword: 'Quên mật khẩu?',

  registerEmail: 'Địa chỉ email đã đăng ký',
  registerUsername: 'Tên đăng nhập đã đăng ký',
  resetPassword: 'Đặt lại mật khẩu',
  emailPlaceholder: 'Nhập vào email',
  usernamePlaceholder: 'Nhập vào tên đăng nhập',

  otpCode: 'Mã OTP',
  otpCodePlaceholder: 'Nhập mã OTP',

  setNewPass: 'Đặt mật khẩu mới',
  backToLogin: 'Quay lại đăng nhập',
  passwordConfirmText: 'Xác nhận mật khẩu',
  requiredText_1: 'Một mã OTP xác nhận đã được gửi đến địa chỉ email ',
  requiredText_1_2: 'của bạn.',
  requiredText_2: 'Vui lòng kiểm tra email ',
  requiredText_2_2: 'và làm theo hướng dẫn chi tiết.',
  requiredText_3: 'Không nhận được email?',
  requiredText_4: 'Thử lại',

  newPasswordConfirm: 'Xác nhận mật khẩu mới',
  newPassword: 'Mật khẩu mới',
  passwordConfirm: 'Xác nhận mật khẩu',
  requiredNewPassword_1: 'Yêu cầu mật khẩu mạnh:',
  requiredNewPassword_2: 'Ít nhất 8 ký tự',
  requiredNewPassword_3: 'Phải được bao gồm chữ hoa và chữ thường, số',
  requiredNewPassword_4:
    'Không chứa các từ là các phần của email (3 ký tự liên tiếp).',
  setNewPassword: 'Đặt mật khẩu mới',

  continue: 'Xác nhận',
}
