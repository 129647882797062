export default {
  title: 'Fee Configs',
  titleList: 'Fee Configs List',
  feeType: 'Fee Type',
  airline: 'Airline code',
  distributor: 'Source',
  amount: 'Amount',
  FLIGHT_SEGMENT: 'Transit in segment / passenger',
  FLIGHT_ITINERARY: 'Segment / passenger',
  INLAND: 'INLAND',
  INTERNATIONAL: 'INTERNATIONAL',
  createFeeConfigError: 'Error create fee config ',
  createFeeConfigSuccess: 'Create fee config successful',
  domesticFee: 'Domestic fee',
  internationalFee: 'International fee',
  domesticFeeList: 'Domestic fee list',
  internationalFeeList: 'International fee list',
  children: 'Child',
  infant: 'Infant',
  adult: 'Adult',
  ticketType: 'Ticket type',
  domesticTicket: 'Domestic',
  internationalTicket: 'International',
  placeholder: {
    feeType: 'Select Fee Type',
    airline: 'Enter Airline Code',
    ticketType: 'Select Ticket Type',
    amount: 'Enter amount',
    distributor: 'Enter Source',
    profileName: 'Enter Profile Name',
  },
  addMore: 'Add more',
  applyAll: 'Apply for all',
  columns: {
    airline: 'Flight system',
    feeType: 'Fee type',
    adultAmount: 'Adult',
    childAmount: 'Child',
    infantAmount: 'Infant',
    updatedAt: 'Update At',
    action: 'Action',
    amount: 'Fee',
    ticketType: 'Ticket type',
  },
  create: {
    title: 'Create new service fee config',
    buttonTitle: 'Create new service fee config',
  },
  profileName: 'Profile name',
  setAsDefault: 'Set as default',
  deleteProfile: 'Delete profile',
  default: 'Default',
  updateFeeConfigSuccess: 'Update fee config successful',
  updateFeeConfigError: 'Error update fee config',
}
