export default {
  create: {
    title: 'Thêm mới',
    createSuccesful: 'Thêm khuyến mãi theo tài khoản thành công',
    createError: 'Lỗi khuyến mãi theo tài khoản',
  },
  fields: {
    no: 'STT',
    employee: 'Tài khoản',
    employeeCode: 'Mã nhân viên',
    source: 'Hệ thống đặt vé',
    promoCode: 'Mã giảm giá',
    maxApply: 'Số lượng áp dụng tối đa',
    promoCodeApplied: 'Số lượng đã áp dụng',
    status: 'Trạng thái hoạt động',
    created: 'Thời gian tạo',
    updated: 'Thời gian sửa đổi',
  },
  placeholder: {
    employeeCode: 'Nhập Mã nhân viên: EMP1000220, EMP1000001, ...',
    source: 'Chọn Hệ thống đặt vé',
    promoCode: 'Nhập Mã giảm giá',
    maxApply: 'Nhập Số lượng áp dụng tối đa',
  },
  list: {
    title: 'Danh sách khuyến mãi hãng theo tài khoản',
    fetchError: 'Lỗi tải danh sách',
  },
  detail: {
    title: 'Chi tiết khuyến mãi hãng theo tài khoản',
    editSuccessful: 'Sửa khuyển mãi hãng theo tài khoản thành công',
    editError: 'Lỗi sửa khuyến mãi hãng theo tài khoản',
  },
}
