import { env } from '@/libs/env'

export default {
  reservation: 'Đặt chỗ',
  bookingCode: 'Mã đặt chỗ',
  status: 'Trạng thái',
  bookingInvoiceCode: 'Mã đơn hàng',
  rebook: 'Rebook',
  fullname: 'Khách',
  itinerary: 'Hành trình',
  createdAt: 'Ngày đặt chỗ',
  action: 'Thao tác',
  note: 'Ghi chú',
  index: '#',
  classBooking: 'Hạng vé',
  availability: 'Số chỗ có sẵn',
  priceTemp: 'Giá tạm tính',
  result: 'Kết quả',
  continue: 'Tiếp tục',
  information: 'Thông tin',
  qrcode: 'Thông tin QR',
  serviceName: 'Tên gói',
  priceToPay: 'Tiền thanh toán',
  insurancePrice: 'Giá bảo hiểm',
  ip: 'Địa chỉ IP',

  'startDate-endDate': 'Ngày đặt vé',
  flightDate: 'Ngày bay',
  flightTime: 'Giờ bay',
  arrivalTime: 'Giờ đến',
  bookDate: 'Ngày đặt vé',
  brand: 'Hãng',
  airline: 'Hãng hàng không',

  editNote: 'Sửa ghi chú',
  notePlaceholder: 'Nhập ghi chú',
  payment: 'Thanh toán',
  exportTicket: 'Mặt vé',
  exportSms: 'Tin nhắn',
  bookingDetail: 'Chi tiết vé',
  contact: 'Liên hệ',
  addOn: 'Dịch vụ mua thêm',
  baggage: 'Hành lý',
  seat: 'Chỗ ngồi',
  seatNum: 'Số ghế',
  otherSsr: 'Dịch vụ khác',
  fare_basic: 'Fare basis code',
  paxType: 'Hành khách',
  bookingClass: 'Hạng vé',
  totalPrice: 'Tổng giá',

  titleOptions: {
    ADULT: {
      MR: 'Ông',
      MRS: 'Bà',
      MS: 'Cô',
      MSTR: 'Bé trai',
      MISS: 'Bé gái',
    },
    CHILD: {
      MSTR: 'Bé trai',
      MISS: 'Bé gái',
      CHD: 'Trẻ em',
      MR: 'Ông',
      MRS: 'Bà',
      MS: 'Cô',
    },
    INFANT: {
      MSTR: 'Em bé trai',
      MISS: 'Em bé gái',
      MR: 'Em bé trai',
      MRS: 'Em bé gái',
      MS: 'Em bé gái',
    },
  },

  genderOptions: {
    ADULT: {
      MALE: 'Quý ông',
      FEMALE: 'Quý cô',
    },
    CHILD: {
      MALE: 'Bé trai',
      FEMALE: 'Bé gái',
    },
    INFANT: {
      MALE: 'Em bé trai',
      FEMALE: 'Em bé gái',
    },
  },

  historyActions: {
    columns: {
      orderNo: 'STT',
      date: 'Thời gian',
      action: 'Thao tác',
      amount: 'Thông tin',
      executor: 'Người thực hiện',
      newValue: 'Giá trị thay đổi',
      oldValue: 'Giá trị cũ',
      description: 'Mô tả',
      chargeTime: 'Thời gian',
      segments: 'Chuyến bay',
      paxId: 'Tên hành khách',
      tax: 'Thuế VAT',
      netFare: 'Số tiền',
      totalRefund: 'Tổng tiền hoàn',
      penalty: 'Phí hoàn',
      total: 'Tổng',
      code: 'Mã',
      passenger: 'Khách hàng',
      ticketNumber: 'Số vé',
      price: 'Giá',
    },
    title: {
      general: 'Lịch sử thao tác',
      payment: 'Lịch sử thanh toán',
      fromSource: 'Lịch sử thao tác từ hãng',
      charges: 'Chi tiết giá vé',
      all: 'Tất cả',
    },
    CREATE_BOOKING: 'Đặt chỗ',
    CREATE_AND_PAYMENT_BOOKING: 'Đặt chỗ & thanh toán',
    PRE_PAYMENT_BOOKING: 'Thực hiện thanh toán',
    PAYMENT_BOOKING: 'Thanh toán thành công',
    CANCEL_BOOKING: 'Huỷ đặt chỗ',
    MODIFY_ADD_FLIGHT: 'Thêm chuyến bay',
    MODIFY_CHANGE_FLIGHT: 'Đổi chuyến bay',
    MODIFY_ANCILLARY: 'Sửa dịch vụ mua thêm',
    MODIFY_RESERVE_SEAT: 'Đổi chỗ ngồi',
    MODIFY_PAX_INFO: 'Sửa thông tin hành khách',
    PAYMENT_FOR_ANCILLARY: 'Thanh toán dịch vụ mua thêm',
    CALCULATE_PRICE: 'Tính giá',
    SPLIT_BOOKING: 'Tách hành khách',
    CREATE_BOOKING_SPLIT: 'Tạo đặt chỗ từ đặt chỗ bị tách',
    VIEW_BOOKING: 'Xem đặt chỗ',
    CREATE_BOOKING_OUT_SIDE: 'Đồng bộ code ngoài hệ thống',
    SET_AUTO_ISSUE: 'Đặt giờ xuất vé',
    SET_AUTO_ISSUE_AND_CANCEL: 'Đặt giờ/Huỷ đặt giờ xuất vé',
    SEND_EMAIL: 'Gửi email',
    VOID_TICKET: 'VOID vé',
    MODIFY_DOCUMENT: 'Sửa thông tin hộ chiếu',
    MODIFY_REMARK: 'Sửa ghi chú (remark)',
    REFUND_TICKET: 'Hoàn vé',
    EXCHANGE_TICKET: 'Đổi vé VNA',
    SHORTEN_TICKET: 'Xem vé rút gọn',
    // CONFIRM_ALL_SEGMENT: 'CONFIRM_ALL_SEGMENT',
    // CMD_MODIFY_REMARK: 'CMD_MODIFY_REMARK',
    // CMD_OSI: 'CMD_OSI',
    CMD_QUEUE: 'Gửi Queue',
    NOTE: 'Sửa ghi chú',
    SPECIAL_SERVICE: 'Đặt dịch vụ đặc biệt',
    MODIFY_SPECIAL_SERVICE: 'Sửa dịch vụ đặc biệt',
    MODIFY_TICKET_DETAILS: 'Xoá/Sửa số vé',
    CONFIRM_SEGMENTS: 'Xác nhận chỗ',
    MODIFY_FFTV: 'Chỉnh sửa thẻ thành viên',
    SET_AUTO_ISSUE_TIME: 'Đặt giờ xuất vé',
    MODIFY_OSI: 'Sửa OSI',
    CANCEL_SEATS: 'Huỷ chỗ ngồi',
    SET_LOW_FARE_TRACKING: 'Bật canh vé giá rẻ',
    CANCEL_LOW_FARE_TRACKING: 'Tắt canh vé giá rẻ',
    UPDATE_DISCOUNT: 'Cập nhật chiết khấu',
    MODIFY_PAYMENT_ANCILLARY: 'Thanh toán dịch vụ mua thêm',
    MODIFY_PAYMENT_RESERVE_SEAT: 'Thanh toán chỗ ngồi',
    STOP_PAYMENT_REMINDER: 'Thay đổi thông báo nhắc xuất vé',
    ACTIVE: 'Hiện mã đặt chỗ',
    DEACTIVE: 'Ẩn mã đặt chỗ',
    CALCULATE_PRICE_BARGAIN_FINDER: 'Tính giá rẻ nhất',
    CREATE_INSURANCE_DRAFT: 'Chọn gói bảo hiểm',
    CREATE_COVER_NOTE: 'Tạo cover note',
    EDIT_COVER_NOTE: 'Chỉnh sửa cover note',
    CREATE_POLICY: 'Thanh toán bảo hiểm',
    CANCEL_INSURANCE: 'Huỷ gói bảo hiểm',
    REFUND_INSURANCE: 'Hoàn gói bảo hiểm',
  },

  recalculatePriceFlight: 'Tính lại giá',
  cancelFlight: env.isCancelItineraryTextButton ? 'Huỷ hành trình' : 'Huỷ chuyến bay',
  addFlight: 'Thêm chặng bay',
  addQuickFlight: 'Thêm nhanh chặng bay',
  'addFlight/DS': 'Thêm hành trình/DS',
  changeFlight: 'Đổi vé',
  changeItinerary: 'Đổi hành trình',
  changeFlights: 'Đổi chuyến bay',
  confirmSC: 'Xác nhận SC',
  separateBooking: 'Tách hành khách',
  addInfant: 'Thêm em bé',
  recalculatePrice: {
    title: 'Tính giá',
    chooseQuoteForPayment: 'Chọn quote thanh toán',
    withDOB: 'Tính giá nhập ngày sinh',
    paxInformation: 'Thông tin hành khách',
    itinerariesInformation: 'Thông tin hành trình',
    'The system will automatically recalculate the price for all passengers on the booking': 'Hệ thống sẽ tự động tính lại giá cho toàn bộ khách trên booking',
  },

  no: 'STT',
  passenger: 'Hành khách',
  paxs: 'Loại hành khách',
  passengerDetails: 'Chi tiết hành khách',
  flightAndBaggage: 'Chặng / Hành lý',
  flight: 'Chuyến bay',
  flightTo: 'Chuyến bay đến',
  Flight: 'Chuyến bay',
  price: 'Giá bán',
  priceNet: 'Giá NET',
  taxAndOtherFees: 'Thuế / phí',
  total: 'Tổng',
  totalPirce: 'Tổng chi phí',
  pricePaidToSupplier: 'Giá trả nhà cung cấp',
  totalAmountToPay: 'Tổng tiền chưa thanh toán',
  totalAmountNeedToPay: 'Tổng tiền cần thanh toán',
  itineraryChangeFare: 'Phí thay đổi hành trình',
  discountAmount: 'Chiết khấu',
  totalAmountPaid: 'Tổng tiền đã thanh toán',
  aircraft: 'Máy bay',
  priceDetails: {
    header: 'Chi tiết giá',
    title: 'Thông tin giá trả nhà cung cấp',
    caution: {
      title: 'QUÝ ĐẠI LÝ LƯU Ý',
      content1: 'Vui lòng kiểm tra kỹ thông tin Tên khách - Hành trình - Ngày/Giờ bay - Giá vé - Hiệu lực Passport/Visa nhập cảnh & transit (nếu có).',
      content2: 'Giá, thuế và tỷ giá có thể thay đổi tại thời điểm xuất vé (Đặc biệt đối với vé SOTO khởi hành từ ngoài Việt Nam).',
      content3: 'Nếu có bất kỳ thắc mắc hoặc sai sót nào Anh/Chị vui lòng báo lại Booker.',
    },
    titleTable: {
      paxType: 'Hành khách',
      flight: 'Chuyến bay',
      amount: 'Số lượng',
      fareBasisCode: 'Fare basis code',
      expirationTime: 'Thời hạn giữ giá',
      lastDateToPurchase: 'Thời gian hết hạn thanh toán',
      tax: 'Thuế và phí',
      fare: 'Giá vé',
      total: 'Tổng tiền',
      netFare: 'Giá vé',
      feeService: 'Phí dịch vụ',
      agencyFee: 'Phí xuất vé',
    },
    pleaseRecalculatePrice: 'Vui lòng tính lại giá để có thể thanh toán!',
  },
  paymentDetails: 'Thông tin thanh toán',
  sellingPriceDetails: {
    title: 'Thông tin giá bán',
    reSetServiceFee: 'Sửa phí dịch vụ',
    tableColumnsPaxFareInfoTicket: {
      passenger: 'Hành khách',
      netPrice: 'Giá vé',
      tax: 'Thuế và phí',
      feeService: 'Phí dịch vụ',
      total: 'Tổng tiền',
      agencyFee: 'Phí xuất vé',
    },
  },
  back: 'Quay lại',
  detail: 'Chi tiết',
  confirm: 'Xác nhận',
  save: 'Lưu',

  flightHasBeenCancelled: 'Không có hành trình trong mã đặt chỗ',
  selectItineraryToCancel: 'Chọn hành trình muốn huỷ:',
  selectItineraryToRefund: 'Chọn hành trình muốn hoàn:',
  selectItineraryToRecalculatePrice: 'Chọn hành trình muốn tính lại giá:',

  cancelFlightError: 'Lỗi huỷ chuyến bay.',
  cancelFlightSuccessful: 'Huỷ chuyến bay thành công.',

  tax: 'Thuế',
  Fees: 'Phí',
  Passenger: 'Hành khách',
  Price: 'Giá',
  Total: 'Tổng',

  refundFee: 'Phí hoàn vé',
  refundAmount: 'Số tiền được hoàn lại sau khi huỷ',
  chargeAmount: 'Tổng tiền thanh toán',
  refundFlightAmount: 'Số tiền còn lại',
  error: 'Lỗi: Không thể huỷ chuyến bay này',
  errorChangeFlight: 'Lỗi: Không thể thay đổi chuyến bay này',
  loading: 'Đang tải',
  close: 'Đóng',
  submit: 'Xác nhận',
  confirmCancelFlight: 'Bạn chắc chắn muốn huỷ chuyến bay?',
  confirmRefundFlight: 'Bạn chắc chắn muốn hoàn chuyến bay?',
  selectAll: 'Chọn tất cả',
  unselectAll: 'Bỏ chọn tất cả',

  separateBookingText: 'Chọn hành khách muốn tách khỏi vé',
  separateBookingSuccessful: 'Tách hành khách thành công.',
  separateBookingError: 'Tách hành khách không thành công.',
  confirmSeparateBooking: 'Bạn chắc chắn muốn tách hành khách?',

  phoneNumber: 'Số điện thoại',
  cannotChangePhone: 'Thay đổi số điện thoại không được hỗ trợ!',
  cannotChangeEmail: 'Thay đổi email không được hỗ trợ!',
  company: 'Công ty',
  addMorePhone: 'Thêm số điện thoại',
  addMoreEmail: 'Thêm email',
  placeHolderCompany: 'Tên công ty',

  Type: 'Loại hành khách',
  Gender: 'Giới tính',
  Lastname: 'Họ',
  Firstname: 'Tên đệm & Tên',
  Title: 'Danh xưng',
  DoB: 'Ngày sinh',
  Action: 'Hành động',
  edit: 'Sửa',

  findBookingByPnrCode: 'Tìm PNR ngoài hệ thống',
  cancel: 'Huỷ',
  find: 'Tìm kiếm',

  inputPnrPlaceholder: 'Nhập mã PNR',
  inputSourcePlaceholder: 'Nhập mã hệ thống đặt vé',
  source: 'Hệ thống',
  pnrCode: 'Mã PNR',
  cannotFindBooking: 'Không tìm thấy booking',
  selectedExtraBaggage: 'Chọn hành lý bổ sung',
  selectSeat: 'Chọn chỗ ngồi',
  selectOtherServices: 'Chọn dịch vụ khác',
  Available: 'Ghế còn trống',
  Unavailable: 'Ghế không còn trống',

  searchFlight: 'Tìm chuyến bay',
  selectTrip: 'Chọn chuyến bay',
  confirmChangeFlight: 'Bạn có chắc chắn muốn thay đổi hành trình?',
  confirmAddFlight: 'Bạn có chắc chắn muốn thêm chuyến bay này?',
  changeFlightsSuccessful: 'Thay đổi hành trình thành công',
  changeFlightsError: 'Lỗi thay đổi hành trình',
  editInfoPaxList: 'Thay đổi thông tin khách',
  confirmChange: 'Xác nhận thay đổi',
  confirmInfoChange: 'Tính giá và xác nhận thông tin',
  changeInfoPassenger: 'Thay đổi thông tin',
  changeInfoSuccessful: 'Thay đổi thông tin thành công',
  changeInfoError: 'Thay đổi thông tin thất bại',
  ADULT: 'Người lớn',
  CHILD: 'Trẻ em',
  INFANT: 'Em bé',
  errTitle: 'Lỗi',

  paymentAncillary: 'Thanh toán dịch vụ',
  flightsDetails: {
    title: 'Thông tin chuyến bay',
    status: 'Tình trạng chỗ',
    bookingClass: 'Hạng vé',
    airCraft: 'Máy bay',
    distance: 'Khoảng cách (dặm)',
  },
  dkve: 'Điều kiện vé',
  timeHold: 'Thời gian giữ chỗ',
  autoIssueTime: 'Thời gian xuất vé tự động',
  contactInfo: 'Thông tin liên hệ',

  message: {
    'Booking not found': 'Không tìm thấy booking',
    activeReservationSuccess: 'Hiện mã đặt chỗ thành công',
    activeReservationError: 'Lỗi hiện mã đặt chỗ',
    deactiveReservationSuccess: 'Ẩn mã đặt chỗ thành công',
    deactiveReservationError: 'Lỗi ẩn mã đặt chỗ',
    banBookingSuccess: 'Khóa IP thành công',
    unbanBookingSuccess: 'Mở khóa IP thành công',
    banBookingError: 'Lỗi khóa IP',
    submitBookingSuccess: 'Đặt chỗ thành công',
    submitBookingError: 'Lỗi đặt chỗ',
    sendSubmitBookingMailSuccess: 'Gửi vé thành công',
    sendSubmitBookingMailError: 'Lỗi gửi vé',
  },

  placeholderSelect: 'Vui lòng chọn',
  placeholderInput: 'Vui lòng nhập',
  phFirstName: 'Nhập vào Tên đệm & Tên',
  phLastName: 'Nhập vào họ',
  selectAdult: 'Chọn người lớn đi cùng',
  noteAddInfant: 'Em bé phải trong độ tuổi từ 0-2 tuổi tính từ ngày khởi hành',
  noteAddInfant_2: 'Cần có một NGƯỜI LỚN đi cùng để có thể thêm em bé.',
  cantAddInfant: 'Không thể thêm em bé với mã đặt chỗ này.',

  paymentOverLimit:
    'Cảnh báo: Dư nợ của đại lý đặt vé <code class="code-agency">{agencyCode}</code> đã đạt tối đa hạn mức thanh toán.',
  paymentWarning:
    'Cảnh báo: Dư nợ hiện tại của đại lý đặt vé <code class="code-agency">{agencyCode}</code> đã gần đạt tối đa hạn mức thanh toán.',
  isWarningLimitPercent: 'Cảnh báo: Dư nợ hiện tại của đại lý đặt vé <code class="code-agency">{agencyCode}</code> vượt {percent}% hạn mức thanh toán.',
  isOverLimitParent:
    'Cảnh báo: Dư nợ của đại lý <code class="code-agency">{agencyCode}</code> đã đạt tối đa hạn mức thanh toán.',
  issueWarning:
    'Nếu dư nợ vượt quá giới hạn tổng số dư nợ sẽ không xuất được vé.',
  agencyName: 'Tên đại lý',
  book: 'đặt vé',
  pay: 'xuất vé',
  balanceLimit: 'Số dư nợ hiện tại', // / Giới hạn tổng số dư nợ
  limitCredit: 'Giới hạn tổng số dư nợ',
  issueTicketPrice: 'Giá vé tạm tính',
  ticketPrice: 'Tổng giá vé',
  discount: 'Chiết khấu (tạm tính)',
  discountAfter: 'Chiết khấu (trả sau)',
  balanceAfterPayment: 'Dư nợ sau thanh toán',
  'Check latest promotion': 'Kiểm tra chiết khấu mới nhất',
  'Calculate promotion': 'Tính chiết khấu',
  'Promotion is no longer available, please recalculate the promotion': 'Chiết khấu không còn khả dụng, vui lòng tính lại chiết khấu',
  'Promotion has updated, please recalculate the promotion': 'Chiết khấu có sự thay đổi, vui lòng tính lại chiết khấu',
  'Latest promotion has applied': 'Đã áp dụng chiết khấu mới nhất',
  calcPriceForChangeFlight:
    'Hệ thống đang tính giá cho chuyến bay, vui lòng đợi trong giây lát',
  oldFlight: 'Hành trình cũ',
  newFlight: 'Hành trình mới',
  confirmAddToFlight: 'Xác nhận thêm chuyến bay',
  noNote: 'Không có ghi chú',
  modifyDocuments: ' Thông tin hộ chiếu',
  ticketType: 'Loại vé',
  eTicket: 'Số vé',
  amount: 'Số lượng',
  fare: 'Giá vé',
  changeTicket: 'Đổi vé VNA',
  refundTicket: 'Hoàn vé',
  refundTicketVJ: 'Hoàn định danh',
  allowRefund: 'Được phép hoàn',
  disallowRefund: 'Không được hoàn',
  identification: 'định danh',
  eticketInfo: 'Thông tin số vé',
  noTicketNum: 'Chưa có số vé',
  eticketList: 'Danh sách số vé',
  addTicket: 'Thêm số vé',
  addTicketToBooking: 'Thêm số vé vào booking',
  agencyInfo: 'Thông tin liên hệ của đại lý',
  customerInfo: 'Thông tin liên hệ khách hàng',
  copyEticketSuccess: 'Đã sao chép số vé',
  copyEticketError: 'Lỗi copy số vé',

  voidTicket: {
    title: 'Void số vé',
    titleExternal: 'Void số vé ngoài',
    paymentTime: 'Thời gian thanh toán',
    selectTicketToVoid: 'Chọn số vé',
    QH_void1: 'Hành động này sẽ VOID TOÀN BỘ số vé đã có trong vé này',
    QH_void2: 'VOID vé chỉ áp dụng khi void vé TRONG ngày xuất và ngày xuất KHÁC ngày bay',
    VN_void1: 'Quy định VOID vé xuất trong ngày bay',
    VN_void2: 'Void hành trình nội địa trước {time} giờ trước giờ bay',
    VN_void: 'Thời hạn void vé: trước 23h00 ngày xuất vé, trước thời gian khởi hành 03 giờ (đối với vé Nội địa), trước thời gian khởi hành 06 giờ (đối với vé Quốc tế).',
    lockVoid: 'không đủ điều kiện void vé',
    caution: 'Nếu quý khách không có nhu cầu bay, vui lòng huỷ chỗ.',
  },

  noBaggage: 'Không có hành lý mua thêm trên hành trình này',
  noOtherServices: 'Không có dịch vụ mua thêm trên hành trình này',

  payments: {
    confirmReCalcPrice: 'Xác nhận thông tin và tính lại giá?',
    checkCommissionCantVoid1: 'Hệ thống chưa hỗ trợ thanh toán với booking này.',
    checkCommissionCantVoid2: 'Vui lòng liên hệ booker để được giúp đỡ!',
    isRequiredPassport: 'Vui lòng nhập hộ chiếu của hành khách trước khi thanh toán!',
    isNotEqualLengthTrip: 'Số lượng hành trình trong mã đặt chỗ và trong bảng báo giá có sự khác nhau. Vui lòng kiểm tra kỹ, đặc biệt là nếu quý đại lý đã thêm hành trình và chưa thực hiện tính lại giá!',
    pleaseRecalculatePrice: 'Vui lòng tính lại giá để có thể thanh toán!',
    emptyItineraries: 'Mã đặt chỗ không còn hành trình để thanh toán',
    confirmPaymentAncillary: 'Xác nhận thanh toán dịch vụ thêm?',
    paymentWithDob: 'Xuất vé có ngày tháng năm sinh.',
    paymentWithDobNote: 'Không chọn chức năng này nếu chưa thực hiện "Tính lại giá và nhập ngày sinh" trước đó.',
  },

  // for booking sms
  copy: 'Sao chép',
  code: 'Mã đặt chỗ',
  route: 'Chặng',
  at: 'Lúc',
  date: 'ngày',
  baggageWithFee: 'Dịch vụ bổ sung',
  totalAmount: 'Tổng tiền',
  sms: {
    combinate: 'Gộp SMS',
    note: 'Vui lòng kiểm tra lại tất cả thông tin đặt chỗ chính xác trước khi gửi khách hàng.',
    note1Domestic: 'Mang theo Căn cước công dân còn hạn',
    note1International: 'Mang theo Hộ chiếu (Passport)',
    note2Domestic: 'Có mặt trước 90 phút',
    note2International: 'Có mặt trước 180 phút',
    vnWithAccent: 'Tiếng Việt có dấu',
    vnWithAccentShorten: 'Rút gọn',
    vnNoAccent: 'Tieng Viet khong dau',
    en: 'Tiếng Anh',
    copySuccess: 'Đã sao chép nội dung vào bộ nhớ tạm!',
    copyFail: 'Sao chép nội dung thất bại!',
  },
  retain: 'Lưu tính giá',
  full_name: 'Họ Tên',
  pax_type: 'Loại hành khách',
  pax_id: 'Mã hành khách',
  accountCode: 'Mã tài khoản',
  ADT: 'Người lớn',
  CHD: 'Trẻ em',
  CNN: 'Trẻ em',
  INF: 'Em bé',

  meals: 'Đặt suất ăn',
  errGetEticket: 'Lỗi lấy chi tiết số vé',
  confirmExchange: 'Bạn có chắc chắn muốn thay đổi?',
  chooseSegment: 'Chọn chuyến bay mới muốn xuất đổi vé',
  confirmRefundTicket: 'Bạn có chắc chắn hoàn vé không?',
  shortenBooking: {
    titleBooking: 'Vé rút gọn',
    titleBooking1A: 'Vé rút gọn 1A',
    titleHistory: 'History',
    titleBaggages: 'Hành lý',
    titleFareRules: 'Điều kiện',

    copyBooking: 'Copy vé rút gọn',
    copyBooking1A: 'Copy vé rút gọn 1A',
    copyHistory: 'Copy history',
    copyBaggages: 'Copy hành lý',
    copyFareRules: 'Copy điều kiện vé',

    reloadButtonTooltip: 'Tải lại',
  },
  history: {
    title: 'HISTORY',
    reloadButtonTooltip: 'Tải lại HISTORY',
  },
  remarks: {
    title: 'Thêm ghi chú (Remarks)',
    send: 'Gửi ghi chú',
  },

  sendQueues: {
    title: 'Gửi Queues',
    queueCode: 'Mã queue',
    queueText: 'Remark',
    placeholderQueueCode: 'Lựa chọn mã queue',
    placeholderQueueText: 'Điền vào remark',
    placeholderChangeName: 'Nhập tên đúng của khách',
    changeName: 'Sửa tên khách',
    chooseQueue: 'Chọn queue muốn gửi',
    oldPassenger: 'Chọn khách muốn đổi tên',
    chooseSegmentToSC: 'Chọn chuyến SC',
    noSegmentTK: 'Không có chuyến TK.',
  },

  osi: {
    title: 'Thêm dich vụ đi kèm (OSI)',
    text: 'Thông báo',
    delete: {
      title: 'Xoá OSI',
      osiList: 'Danh sách OSI',
    },
    chooseOsiCode: 'Dịch vụ cần thêm',
    placeholderRemark: 'Ghi rõ chức danh, ví dụ: DAI BIEU QUOC HOI',
  },

  confirmSegments: {
    title: 'Xác nhận chỗ',
    confirm: 'Bạn có chắc chắn muốn Xác nhận chỗ ?',
  },

  haveArunk: 'Có thêm chặng mặt đất',
  notiFromSystem: 'Thông báo từ hệ thống',

  ALL: 'Tất cả',
  HOLD: 'Giữ chỗ',
  PAID: 'Đã thanh toán',
  PARTIALLY_PAID: 'Đã thanh toán - treo phí',
  PARTIALLY_PAID_ONLY: 'Có treo phí',
  UNPAID: 'Chưa thanh toán',
  CANCELLED: 'Đã hủy',
  CANCEL: 'Đã hủy',
  EXPIRED: 'Quá hạn',
  ACTIVE: 'Giữ chỗ',
  DISAPPEAR: 'Đã xoá',
  DRAFT: 'Draft',

  needPayment: 'Cần thanh toán',
  thisFlightCancelled: 'Hành trình này đã bị hủy.',
  ancillaries: 'Dịch vụ mua thêm',
  services: 'Dịch vụ',
  noAddons: 'Không có dịch vụ mua thêm nào trên hành trình.',
  addBaggage: 'Thêm hành lý',
  noSeatMapData: 'Không có dữ liệu chỗ ngồi!',
  addSeat: 'Thêm chỗ ngồi',
  detailSeat: 'Chi tiết chỗ ngồi',
  addOtherServices: 'Thêm dịch vụ khác',
  addServices: 'Thêm dịch vụ',
  segment: 'Chặng bay',
  amountRefunded: 'Số tiền bảo lưu định danh',
  loadingData: 'Đang tải dữ liệu...',
  bargainFinder: 'Tự động tính giá rẻ nhất',
  booker: 'Người đặt',
  totalPriceItinerary: 'Tổng tiền hành trình',
  iAmConfirm: 'Tôi đã hiểu và đồng ý',

  specialServices: {
    title: 'Dịch vụ đặc biệt',
    itineraries: {
      title: 'Chọn hành trình',
    },
    passengers: {
      title: 'Chọn hành khách',
    },
    ss: {
      title: 'Chọn dịch vụ đặc biệt',
      freeText: 'Ghi chú (free text)',
    },
    book: 'Đặt dịch vụ đặc biệt',
  },

  customerDetails: {
    title: 'Thông tin khách hàng',
    name: 'Họ Tên',
    phone: 'Điện thoại',
    email: 'Email',
    address: 'Địa chỉ',
    domain: 'Tên miền',
  },

  bookingInvoice: {
    title: 'Thông tin hóa đơn bán lẻ',
    companyName: 'Tên Công Ty',
    address: 'Địa chỉ',
    cityName: 'Thành phố',
    taxCode: 'Mã số thuế VAT',
    receiver: 'Người nhận',
    email: 'Email',
  },

  bookerDetails: {
    title: 'Thông tin Đại lý',
    rtlTitle: 'Thông tin người đặt',
    name: 'Họ Tên',
    phone: 'Điện thoại',
    email: 'Email',
    agency: 'Đại lý',
    parentAgency: 'Đại lý chủ quản',
  },
  removeCancelFee: 'Liên hệ tổng đài để gỡ phí huỷ trước khi thanh toán',
  net: 'Giá',

  showCalculatePrice: {
    columns: {
      key: 'Mã',
      bookingInfos: 'Thông tin hành trình',
      appliedPassengers: 'Hành khách áp dụng',
      basePrice: 'Giá net',
      totalTaxes: 'Tổng thuế phí',
      totalPrice: 'Tổng tiền',
    },
    savePrice: 'Lưu giá',
    title: 'Hiển thị giá tính lại',
    headerInformationTitle: 'Thông tin hành trình mới',
  },

  agency: 'Đại lý',
  customer: 'Khách hàng',
  chooseAgencyOrCustomerTitle: 'Chọn đại lý hoặc khách hàng để gán vào booking',
  birthday: 'Ngày sinh',
  quantity: 'Số lượng',
  viewEticket: 'Xem số vé',
  viewEticket1S: 'Xem số vé 1S cũ',
  viewEticket1G: 'Xem số vé 1G',
  viewEticket_VN1A_QH_1G: 'Xem số vé VN1A, 1G, QH',
  languageCode: {
    VIETNAMESE: 'Tiếng Việt',
    ENGLISH: 'Tiếng Anh',
    FRENCH: 'Tiếng Pháp',
    SPANISH: 'Tiếng Tây Ban Nha',
    PORTUGUESE: 'Tiếng Bồ Đào Nha',
    ITALIAN: 'Tiếng Ý',
    GERMAN: 'Tiếng Tiếng Đức',
    SWEDISH: 'Tiếng Thuỵ Điển',
    GREEK: 'Tiếng Hy Lạp',
    SIMPLIFIED_CHINESE: 'Tiếng Trung Giản Thể (zh)',
    TRADITIONAL_CHINESE: 'Tiếng Trung Truyền Thống (zh-Hant)',
    JAPANESE: 'Tiếng Nhật Bản',
    KOREAN: 'Tiếng Hàn Quốc',
    THAI: 'Tiếng Thái',
    RUSSIAN: 'Tiếng Nga',
    POLISH: 'Tiếng Ba Lan',
    TAIWAN: 'Tiếng Đài Loan',
  },
  sendEmail: {
    title: 'Gửi Email',
    chooseLanguage: 'Lựa chọn ngôn ngữ cho nội dung của email gửi đi',
    sendEmailSuccess: 'Gửi email thành công',
    sendEmailError: 'Lỗi gửi email',
  },
  ban: {
    title: 'Khóa IP',
    unban: 'Mở khóa IP',
  },
  f1PaymentForF2: 'Đại lý này không đủ dư nợ để thanh toán. Bạn có chắc chắn muốn thanh toán cho Đại lý này không?',

  paymentReminder: {
    title: 'Nhắc xuất vé',
    confirm: '{statusChange} thông báo nhắc xuất vé?',
    activate: 'Đã bật thông báo nhắc xuất vé!',
    deactivate: 'Đã tắt thông báo nhắc xuất vé!',
  },

  Passport: 'Hộ chiếu',
  Accompanying_infant: 'Em bé đi cùng:',
  Accompanying_adult: 'Người lớn đi cùng:',

  lowFare: {
    lowfareTracking: 'Canh vé giá rẻ',
    selectTrip: 'Vui lòng chọn hành trình để thực hiện',
    flightStatus: 'Tình trạng chuyến bay',
    noItineraries: 'Không tìm thấy hành trình!',
    rangeFare: 'Nhập vào khoảng giá',
    lowestFare: 'Nhập vào giá tiền thấp nhất:',
    highestFare: 'Nhập vào giá tiền cao nhất',
    required: 'bắt buộc',
    noteGetPrice: 'Hệ thống sẽ canh vé thấp hơn hoặc bằng giá này',
    noItiSelect: 'Chưa chọn hành trình!',
    turnOn: 'Bật canh vé rẻ',
    infoLowFare: 'Thông tin canh vé',
    processing: 'Đang thực hiện...',
    success: 'Thành công',
    expired: 'Hết hạn',
    turnOff: 'Tắt canh vé',
    notTurnOn: 'Chưa bật canh vé rẻ',
    note: 'LƯU Ý:',
    note1: '- Vui lòng hủy code cũ sau khi đã canh vé thành công càng sớm càng tốt (lưu ý kiểm tra lại giá và điều kiện vé), tất cả code bị dupe có thể bị huỷ trước thời gian cảnh báo.',
    note2: '- Tính năng Canh vé hiện tại chỉ hỗ trợ hãng hàng không Vietravel Airlines và trạng thái vé là giữ chỗ (HOLD).',
    note3: '- Hỗ trợ tạo đặt chỗ cho một phần số lượng hành khách trong tổng số hành khách với trường hợp số chỗ còn trống của vé tìm được ít hơn tổng số lượng hành khách trong đặt chỗ hiện tại.',
    option: 'Tuỳ chọn',
    deadlineTask: 'Hẹn giờ tắt canh vé',
    setTimeBefore: 'Vui lòng đặt thời gian trước:',
    timeEndTracking: 'Thời gian kết thúc canh vé:',
    lowfareType: 'Cách thức canh vé:',
  },
  hideBooking: 'Ẩn mã đặt chỗ',
  showBooking: 'Hiện mã đặt chỗ',
  hideBookingConfirm: 'Bạn chắc chắn muốn {type} mã đặt chỗ',
  showHideBooking: 'Ẩn/hiện Mã đặt chỗ',
  Hide: 'Ẩn',
  Show: 'Hiện',
  lockBalance: 'Treo quỹ',
  All: 'Tất cả',
  BBClub: 'Bamboo Club',
  BSV: 'Bông Sen Vàng',
  membershipCard: 'Thẻ thành viên',
  successCreateTTV: 'Số thẻ thành viên được tạo thành công sẽ được gửi về email của bạn.',
  errCreateMembershipCardAirline: 'Lỗi tạo thẻ thành viên hãng',
  fareBasisCode: 'Fare basis code',
  newMembershipCreated: 'Số thẻ {airline} mới được tạo:',
  dontHaveMembershipCardYet: 'Chưa có thẻ thành viên?',
  createNew: 'Tạo mới',
  cardNumber: 'Số thẻ',
  findBookingVJ: {
    title: 'Tra cứu vé Vietjet',
    headerFilter: 'Tìm kiếm đặt chỗ',
    headerResult: 'Kết quả tìm kiếm',
    departureAirport: 'Khởi hành từ sân bay',
    departureDateFrom: 'Ngày khởi hành từ',
    departureDateTo: 'Ngày khởi hành đến',
    createDateFrom: 'Đặt chỗ từ ngày',
    createDateTo: 'Đặt chỗ đến ngày',
    findAirportPleaseSearch: 'Nhập để tìm kiếm sân bay',
    no: 'STT',
    bookingCode: 'Mã đặt chỗ',
    passenger: 'Người đại diện',
    itineraries: 'Chuyến bay',
    status: 'Trạng thái',
    dateCreate: 'Ngày đặt',
    key: 'Mã',
  },
  placeHolderSearchText: 'Tìm kiếm tên hành khách, tên người đặt, ...',
  placeHolderFindPNR: 'Tìm kiếm PNR trong hệ thống',

  bookingStatistic: {
    title: 'Thống kê hệ thống giữ chỗ',
    startFlightDate: 'Ngày bay từ',
    endFlightDate: 'Ngày bay đến',
    source: 'Hệ thống đặt vé',
    no: 'STT',
    itineraries: 'Hành trình',
    flightNumber: 'Chuyến bay',
    bookingCode: 'Mã đặt chỗ',
    detail: '',
    flightDate: 'Ngày bay',
    totalBooking: 'Tổng số',
    paxLists: 'Hành khách',
    totalPassenger: 'Tổng số hành khách',
    findAll: 'Tìm tất cả',
  },
  lowFareTracking: {
    title: 'Quản lý canh vé rẻ',
    startDate: 'Từ ngày',
    endDate: 'Đến ngày',
    source: 'Hệ thống',
    no: 'STT',
    flightNumber: 'Chuyến bay',
    bookingCode: 'Mã đặt chỗ',
    createdAt: 'Ngày đặt chỗ',
    passengers: 'Hành khách',
    flight: 'Chuyến bay',
    delete: 'Xóa',
    all: 'Tất cả',
    trackingSuccess: 'Canh vé thành công',
    trackingNotSuccess: 'Canh vé không thành công',
    tracking: 'Đang canh vé',
    lowFareTrackingCode: 'Vé canh thành công',
    lowFareTrackingStatus: 'Trạng thái canh vé',
    success: 'Thành công',
    processing: 'Đang thực hiện',
    cancelled: 'Không thành công',
    booker: 'Người đặt',
  },
  pastDayBooking: {
    title: 'Danh sách vé qua ngày bay',
  },

  assignBooking: {
    title: 'Chuyển mã công nợ',
  },
  specifiedPassengers: 'Ngày sinh hành khách',
  specifiedPassengersPlaceholder: 'Nhập ngày sinh hành khách (15/04/1999, 15-04-1999)',
  filters: {
    agencyCode: 'Đại lý',
    createdBy: 'Nhập mã nhân viên',
  },
  linkFareRule: 'Link điều kiện vé',
  addBSV: 'Thêm BSV',
  addTTV: 'Thêm TTV',
  editCtcmCtceFor1g: {
    title: 'Thêm/Xoá CTCE/CTCM',
    addTitle: 'Thêm CTCE/CTCM',
    removeTitle: 'Xoá CTCE/CTCM',
    emailPlaceholder: 'Nhập email',
    phonePlaceholder: 'Nhập số điện thoại',
    contentSuccess: 'Thêm/Xoá CTCM/CTCE thành công',
    contentError: 'Lỗi thêm/Xoá CTCM/CTCE',
  },
  sendSubmitBooking: 'Gửi đặt chỗ',
  submitBooking: 'Đặt chỗ',
  sendSubmitBookingMail: 'Gửi vé',
  submitBookingInfo: 'Đặt/giữ chỗ theo thông tin bản nháp',
  passengerType: 'Loại khách',
  paymentStatus: 'Tình trạng thanh toán',
  iAgree: 'Tôi đã hiểu và đồng ý',
  hideFeature: 'Ẩn tính năng',
  showFeature: 'Hiện tính năng',
  otherServiceProduct: 'Sản phẩm khác',
  roundtripTicket: 'Vé khứ hồi',
  splitIntineraryTicket: 'Vé tách chặng',
  groupTicket: 'Vé đoàn',
  splitPassengerTicket: 'Vé tách hành khách',
  benefit: 'Lợi nhuận',
  removeInfant: 'Xoá em bé',
  removeInfantConfirm: 'Xác nhận xoá em bé {lastName} / {firstName} ?',
  'Passengers who already have a membership card, please delete the old card if you want to change!': 'Hành khách đã có thẻ thành viên, vui lòng xoá thẻ cũ nếu muốn thay đổi!',
  'Error in retrieving information of adults accompanying the infant!': 'Lỗi lấy thông tin người lớn đi cùng em bé!',
  bookingCancelled: 'Mã đặt chỗ đã bị huỷ',
  'No notification from the system': 'Không có thông báo từ hệ thống',
  'Business lounge': 'Phòng chờ thương gia',
  'information & QR': 'Thông tin & QR',
  paid: 'ĐÃ THANH TOÁN',
  insuranceServices: 'Bảo hiểm chuyến bay',
  createCoverNote: 'Tạo cover note',
  updateCover: 'Đổi gói',
  deleteCover: 'Xoá gói',
  cancelCover: 'Huỷ gói',
  refundCover: 'Hoàn gói',
  createInsurance: 'Mua bảo hiểm hành trình',
  createLounge: 'Thêm phòng chờ thương gia',
  'Please pay for tickets before purchasing additional services!': 'Vui lòng thanh toán vé trước khi mua thêm dịch vụ khác!',
  'No itinerary found in booking': 'Không tìm thấy hành trình trong đặt chỗ',
  'Lounge service is available for flights originating from the city. Ho Chi Minh (SGN)!': 'Dịch vụ phòng chờ khả dụng cho các chuyến bay từ TP. Hồ Chí Minh (SGN)!',
  'Insurance services can only be purchased for international itineraries originating from Vietnam!': 'Chỉ có thể mua dịch vụ bảo hiểm với các hành trình đi quốc tế và xuất phát điểm từ Việt Nam!',
  'No passengers found who can add a new insurance package!': 'Không tìm thấy hành khách có thể thêm mới gói bảo hiểm!',
  'No passengers found!': 'Không tìm thấy hành khách!',
  'QR code information error, please contact booker to handle!': 'Lỗi thông tin QR code, vui lòng liên hệ booker để xử lý!',
  cancelInsurance: 'Huỷ gói bảo hiểm',
  deleteInsurance: 'Xoá gói bảo hiểm',
  cancelInsSuccess: 'Huỷ gói bảo hiểm thành công!',
  deleteInsSuccess: 'Xoá gói bảo hiểm thành công!',
  cancelInsErr: 'Huỷ gói bảo hiểm thất bại, vui lòng kiểm tra lại thông tin!',
  deleteInsErr: 'Xoá gói bảo hiểm thất bại, vui lòng kiểm tra lại thông tin!',
  'The insurance package has reached its effective date and cannot be refunded!': 'Gói bảo hiểm đã đến ngày hiệu lực, không thể hoàn gói !',
  paymentInsurance: 'Thanh toán gói bảo hiểm',
  'Confirmation of insurance package payment:': 'Xác nhận thanh toán gói bảo hiểm: ',
  planPrice: 'Giá tiền',
  'Payment term': 'Thời hạn thanh toán',
  paymentInsSuccess: 'Thanh toán gói bảo hiểm thành công',
  paymentInsErr: 'Thanh toán gói bảo hiểm thất bại, vui lòng liên hệ booker! ',
  domestic: 'Quốc nội',
  international: 'Quốc tế',
  changeInsuranceItemTitle: 'Đổi gói bảo hiểm',
  addInsuranceItemTitle: 'Thêm dịch vụ bảo hiểm',
  addInsurance: 'Thêm bảo hiểm',
  bookingInfos: 'Thông tin hành trình',
  'First departure time': 'Thời gian khởi hành đầu tiên',
  'Retrieving information about insurance packages': 'Đang lấy thông tin các gói bảo hiểm',
  'Error retrieving insurance package information, please contact booker to check again': 'Lỗi lấy thông tin các gói bảo hiểm, vui lòng liên hệ booker kiểm tra lại',
  'Note: International Travel Insurance products apply to ages from 01 day old to 75 years old': 'Lưu ý: Sản phẩm Bảo hiểm Du lịch quốc tế áp dụng cho độ tuổi từ 01 ngày tuổi đến 75 tuổi',
  'Passengers have not been selected yet!': 'Passengers have not been selected yet!',
  'Please select at least one passenger!': 'Vui lòng chọn ít nhất một hành khách!',
  'CHOOSE INSURANCE PACKAGE': 'CHỌN GÓI BẢO HIỂM',
  'INSURANCE BENEFITS': 'QUYỀN LỢI BẢO HIỂM',
  '(Unit: USD)': '(Đơn vị tính: USD)',
  'Hide discount': 'Ẩn lợi nhuận',
  'Show discount': 'Hiển thị lợi nhuận',
  'Old package': 'Gói cũ',
  'Select package': 'Chọn gói',
  Selected: 'Đã chọn',
  'Personal accident': 'Tai nạn cá nhân',
  'Medical costs': 'Chi phí y tế',
  'Medical aid': 'Trợ cứu y tế',
  'See details in the benefits table': 'Xem chi tiết tại bảng quyền lợi',
  'Travel support': 'Hỗ trợ du lịch',
  'IPA Relief Services': 'Dịch vụ cứu trợ IPA',
  Include: 'Bao gồm',
  'Costs related to Covid-19': 'Chi phí liên quan đến Covid-19',
  'Up to 70 years old': 'Đến 70 tuổi',
  'Apply package': 'Áp dụng gói',
  'Subject to limits and health insurance benefits specified in the contract/Certificate of insurance': 'Theo giới hạn, quyền lợi bảo hiểm sức khoẻ quy định tại hợp đồng/Giấy chứng nhận bảo hiểm',
  'Maximum $50,000/person/Insured period, whichever is lower': 'Tối đa $50,000/người/Thời hạn bảo hiểm, tùy số nào thấp hơn',
  'Over 70 years old': 'Trên 70 tuổi',
  '$5,000/person/insurance period, whichever is lower': '$5,000/người/thời hạn bảo hiểm tùy số nào thấp hơn',
  'Maximum $5,000/person/term of insurance, whichever is lower': 'Tối đa $5,000/người/thời hạn bào hiểm,tùy số nào thấp hơn',
  'Create cover note': 'Tạo cover note',
  'Start time': 'Thời gian bắt đầu',
  'Contact phone number': 'Số điện thoại liên hệ',
  'Contact email': 'Email liên hệ',
  'Customer name used': 'Tên khách hàng sử dụng',
  'Please check again and enter complete information': 'Vui lòng kiểm tra lại và nhập đầy đủ thông tin!',
  'Edit cover note information': 'Chỉnh sửa thông tin cover note',
  'Refund of insurance package': 'Hoàn gói bảo hiểm',
  'Insurance package': 'Gói bảo hiểm',
  Reason: 'Lý do',
  'Change itinerary': 'Thay đổi hành trình',
  'Cannot apply for Visa': 'Không xin được Visa',
  'Other reasons': 'Khác',
  'Please select a return reason!': 'Vui lòng chọn một lý do hoàn gói!',
  'Only enter up to 255 characters!': 'Chỉ được nhập tối đa 255 ký tự!',
  'Insurance package completed successfully': 'Hoàn gói bảo hiểm thành công!',
  'Insurance package refund failed, please check the information again': 'Hoàn gói bảo hiểm thất bại, vui lòng kiểm tra lại thông tin',
  'Insurance information': 'Thông tin bảo hiểm',
  'Contract Code': 'Mã hợp đồng',
  'Compensation level': 'Mức bồi thường',
  Area: 'Khu vực',
  'Please wait': 'Vui lòng đợi',
  'DOWNLOAD THE DRAFT CONTRACT': 'TẢI HỢP ĐỒNG DỰ THẢO',
  'DOWNLOAD OFFICIAL CONTRACT': 'TẢI HỢP ĐỒNG CHÍNH THỨC',
  EDIT: 'CHỈNH SỬA',
  'The service can only be purchased 1 hour before the first departure time': 'Chỉ có thể mua dịch vụ trước 1 tiếng so với giờ khởi hành đầu tiên',

  lockedList: {
    title: 'Danh sách treo quỹ',
    columns: {
      no: 'STT',
      bookingCode: 'Mã đặt chỗ',
      agPayment: 'Mã đại lý',
      lockBalanceItem: 'Số tiền treo quỹ',
    },
  },

  'Warning for duplicate tickets!': 'Cảnh báo xuất vé trùng lặp!',
  'The issued ticket has an error with the ticket number hidden. Please wait for 5-10 minutes, open PNR and check again. To avoid duplicate export, please contact the Sales/Booker department to double check the information.': 'Vé đã xuất đang lỗi ẩn số vé. Quý khách chờ trong khoảng thời gian từ 5-10 phút, mở PNR kiểm tra lại. Để tránh tình trạng xuất Dupe, vui lòng liên hệ với bộ phận Sales/Booker để kiểm tra lại thông tin.',
  'Payment error from the airline': 'Lỗi thanh toán từ hãng.',
  'Please double check your booking code before trying again to avoid duplicate tickets': 'Vui lòng kiểm tra lại mã đặt chỗ trước khi thử lại để tránh xuất vé trùng lặp.',
  'Reload booking code': 'Tải lại mã đặt chỗ',
  'Booking code has been cancelled. Unable to pay!': 'Mã đặt chỗ đã bị huỷ. Không thể thanh toán!',
  'Please select quote to pay!': 'Vui lòng chọn quote để thanh toán!',
  'Please check your credit limit for payment': 'Vui lòng kiểm tra hạn mức tín dụng để thanh toán',
  'Tickets are not issued for HL itineraries, agents please check the itinerary information or contact the booker to issue tickets': 'Không xuất vé với các hành trình HL, quý đại lý vui lòng kiểm tra lại thông tin hành trình hoặc liên hệ với booker để xuất vé!',
  'For 1A GDS bookings, please contact the booker to issue tickets': 'Đối với các booking 1A GDS vui lòng liên hệ booker để xuất vé!',
  addDocuments: {
    title: 'Thêm giấy tờ',
    CCCD: 'CMND/CCCD',
    passport: 'Passport',
    birthday: 'Ngày sinh',
    'The current system only supports Passport': 'Hệ thống hiện tại chỉ hỗ trợ thêm Passport',
    'Please enter passenger passport information': 'Vui lòng nhập thông tin hộ chiếu của hành khách!',
  },
  loading2: 'Đang chuẩn bị dữ liệu, vui lòng đợi...',
  'Tickets cannot be combined': 'Không thể gộp mặt vé',
  'No active ticket links found': 'Không tìm thấy vé liên kết còn hoạt động!',
  'Cannot view tickets for inactive bookings': 'Không thể xem gộp mặt vé với booking không còn hoạt động!',
  'No passengers can add passports': 'Không tìm thấy hành khách có thể thêm hộ chiếu!',
  'No passengers can changes info': 'Không tìm thấy hành khách có thể sửa thông tin!',
  formatDoB: 'VD: 15/09/2023 ...',
  modifyPaxsInfo: 'Sửa thông tin khách',
  'Can to change': 'Có thể thay đổi!',
  'Remaining time': 'Thời gian còn lại',
  'Confirmation time has expired, please try again': 'Hết thời gian xác nhận, vui lòng thực hiện lại',
  'Cannot be changed, please check the company regulations or contact the booker for processing': 'Không thể thay đổi, vui lòng kiểm tra lại quy định hãng hoặc liên hệ booker để xử lý',
  'Please contact booker for support': 'Không thể thay đổi, vui lòng liên hệ Booker/Sales!',
  'Please check the service fee again': 'Vui lòng kiểm tra lại phí dịch vụ!',
  passportLessThan6MonthsBeforeFlight: 'Hộ chiếu sắp hết hạn.',
  stopAp: 'Có chặng ẩn tại',
}
