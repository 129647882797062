export default {
  type: 'Type',
  code: 'Code',
  typePlaceholder: 'Select type',
  codePlaceholder: 'Enter code',
  AIRPORT: 'Airport',
  CARRIER: 'Carrier',
  CITY: 'City',
  COUNTRY: 'Country',
  EQUIPMENT: 'Equipment',
  TIMESTAMP: 'Timestamp',
  result: 'Result',
  noResult: 'Not found any result',
  cityCode: 'City code',
  countryCode: 'Country code',
  name: 'Name',

  currencyConversion: 'Currency conversion',
  amount: 'Amount',
  amountPlaceHolder: 'Enter amount',
  from: 'From',
  to: 'To',
  convert: 'Convert',
  bankSellingRate: 'Bank selling rate',
  convertedAmount: 'Converted amount',
  description: 'Description',
}
