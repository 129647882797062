export default {
  addAgency: 'Thêm mới',
  agencies: 'đại lý',

  checkbox: '',
  agencyCode: 'Mã đại lý (Tên đăng nhập)',
  agencyInformation: 'Thông tin đại lý',
  accountsPayableTemp: 'Công nợ tạm tính',
  taxCode: 'Mã số thuế',
  created: 'Thời gian tạo',
  updated: 'Thời gian sửa đổi',
  status: 'Trạng thái hợp đồng',
  activated: 'Trạng thái hoạt động',
  agencyFee: 'Phí xuất vé',
  retail: 'Bán lẻ',
  manager: 'Quản lý',
  createdBy: 'Người tạo',
  updatedBy: 'Người sửa đổi',

  STT: 'STT',
  avatar: 'Ảnh đại diện',
  agencyName: 'Tên đại lý',
  agencyOwner: 'Chủ sở hữu',
  emailAddress: 'Địa chỉ Email',
  sendMonthlyReport: 'Gửi báo cáo hàng tháng',
  agencyManager: 'Quản lý',
  placeholderAgencyManager: 'Chọn Quản lý',

  errFetchAgency: 'Lỗi dữ liệu tìm đại lý',
  notFundAgencyById_1: 'Không có đại lý nào được tìm thấy với ID này. Kiểm tra',
  notFundAgencyById_2: 'Danh Sách đại lý',
  notFundAgencyById_3: 'để tìm kiếm khác.',
  customers: 'Khách hàng',

  agencyDetail: 'Chi tiết đại lý',
  editAgency: 'Sửa thông tin đại lý',
  agencyInfo: 'Thông tin đại lý cơ bản',

  placeholderSearch: 'Tên, email, số điện thoại...',
  labelName: 'Tên đại lý',
  placeholderName: 'Điền vào tên đại lý',
  labelOwner: 'Tên người đại diện',
  placeholderOwner: 'Điền vào tên chủ sở hữu',
  labelOwnerPhone: 'Số điện thoại người đại diện',
  placeholderOwnerPhone: 'Điền vào số điện thoại chủ sở hữu',
  uploadFrontId: 'Tải lên ảnh CCCD mặt trước',
  uploadBackId: 'Tải lên ảnh CCCD mặt sau',
  labelAirline: 'Hãng hàng không',
  placeholderAirline: 'Chọn hãng hàng không',
  labelEmail: 'Email',
  placeholderEmail: 'Điền vào Email',
  labelStatus: 'Trạng thái',
  placeholderStatus: 'Chọn trạng thái',
  labelUsername: 'Tên đăng nhập',
  placeholderUsername: 'Điền vào tên đăng nhập',
  agencyBusinessInfo: 'Thông tin kinh doanh của đại lý',
  labelHolderName: 'Tên chủ tài khoản',
  placeholderHolderName: 'Điền vào tên chủ tài khoản',
  labelIdentityCardNum: 'Số căn cước công dân',
  placeholderIdentityCardNum: 'Điền vào số căn cước công dân',
  labelIdentityCardName: 'Tên căn cước công dân',
  placeholderIdentityCardName: 'Điền vào tên căn cước công dân',
  labelPlaceOfIssue: 'Nơi cấp căn cước công dân',
  placeholderPlaceOfIssue: 'Điền vào nơi cấp căn cước công dân',
  labelMonthlyReport: 'Gửi báo cáo hàng tháng',
  placeholderMonthlyReport: 'Lựa chọn',
  labelTaxCode: 'Mã số thuế',
  placeholderTaxCode: 'Điền mã số thuế',
  airAccountForAgencyText: 'Tài khoản hàng không cho đại lý',
  provideAccount:
    'Vui lòng cung cấp Air Account từ hãng hàng không cho đại lý:',
  labelAirUsername: 'Tên đăng nhập',
  placeholderAirUsername: 'Tên đăng nhập tài khoản hãng hàng không',
  labelLimitCredit: 'Quỹ đại lý',
  labelWarningCredit: 'Giới hạn cảnh báo',
  changeLimit: 'Đổi hạn mức tín dụng',
  inputLimit: 'Nhập vào hạn mức muốn thay đổi',

  errFetchAgencyData: 'Lỗi tìm nạp dữ liệu mới',
  linkAgency_1: 'Chuyển tới',
  linkAgency_2: 'Danh sách đại lý',

  moreDropdown: {
    title: 'Lựa chọn',
    add: 'Thêm mới đại lý',
    export: 'Xuất ra Excel',
    delete: 'Xoá đại lý',
  },

  topup: 'Nạp tiền',
  topupForAgency: 'Tạo lệnh nạp tiền cho đại lý',
  bankName: 'Ngân hàng thụ hưởng',
  accNumber: 'Tài khoản thụ thưởng',
  accHolder: 'Chủ tài khoản',
  branch: 'Chi nhánh',
  amountNumber: 'Số tiền',
  currency: 'VND',
  transferContent: 'Nội dung chuyển khoản',
  generateQRcode: 'Tạo mã QR',

  agencyCompanyName: 'Tên công ty',
  agencyFoundingDate: 'Ngày thành lập',
  agencyAddress: 'Địa chỉ công ty',
  paymentMethod: 'Phương thức thanh toán',
  overdraft: 'Thấu chi',
  prepay: 'Trả trước',
  documentContract: 'Tài liệu hợp đồng',
  birthday: 'Ngày sinh',
  enter: 'Điền vào',
  managerInfo: 'Thông tin nhân viên quản lý',
  managerEmployeesTable: {
    employeeCode: 'Mã nhân viên',
    employeeName: 'Tên nhân viên',
    emailAddress: 'Email',
    phoneNumber: 'Số điện thoại',
    actions: 'Thao tác',
  },
  BEManager: 'Booker',
  SEManager: 'Saler',
  KTVManager: 'KTV, KTT',
  assignManagersError: 'Lỗi thêm quản lý phụ trách ',
  assignManagersSuccess: 'Thêm quản lý phụ trách thành công',
  notChangeYouBalance:
    'Không thể tự thay đổi gới hạn số dư của đại lý, vui lòng liên hệ đại lý cấp cao hơn.',
  configs: {
    hideFee: 'Đã cộng phí xuất vé',
    showFee: 'Chưa cộng phí xuất vé',
    sendMailAirlineOn: 'Cho phép gửi email hãng cho F3',
    sendPhoneAirline: 'Cho phép gửi thông tin hãng về số điện thoại F3',
    sendMailAirlineOff: 'Cho phép gửi email hãng cho F3',
    title: 'Cài đặt',
    enableSetLowFare: 'Cho phép canh vé rẻ',
    disableSetLowFare: 'Không cho phép canh vé rẻ',
    autoDeposit: 'Cho phép nạp tiền tự động',
    disableAutoDeposit: 'Không cho phép nạp tiền tự động',
    autoIssueTicket: 'Cho phép tự động xuất vé',
    disableAutoIssueTicket: 'Không cho phép tự động xuất vé',
    enableBookingGroup: 'Cho phép book đoàn',
    disableBookingGroup: 'Không cho phép book đoàn',
    usePhoneCustomer: 'Gửi số điện thoại khách hàng lên hãng',
    disableUsePhoneCustomer: 'Gửi số điện thoại khách hàng lên hãng',
    enable1G: 'Cho phép bán lẻ tra giá 1G GDS',
    showTicketCondition: 'Hiển thị điều kiện vé',
    disableShowTicketCondition: 'Không hiển thị điều kiện vé',
    enableDraft: 'Cho phép book ảo',
    disableDraft: 'Không cho phép book ảo',
    issueLimit: 'Quyền được xuất vé',
    bookingLimit: 'Quyền được đặt vé',
    refundLimit: 'Quyền được hoàn vé',
    voidLimit: 'Quyền được void vé',
    permissionTitle: 'Cài đặt Quyền',
    featureTitle: 'Cài đặt chức năng',
    retailTitle: 'Cài đặt bán lẻ',
    feeTitle: 'Cài đặt phí xuất vé',
    enableQuickName: 'Cho phép nhập tên nhanh',
    enableRebook: 'Cho phép Rebook',
    limitPaxBooking: 'Giới hạn số lượng hành khách/booking',
    limitTimeSearch: 'Giới hạn thời gian tìm kiếm chuyến bay',
    showPaymentOffice: 'Văn phòng',
    showPaymentHome: 'Tại nhà',
    showPaymentQR: 'QR',
    paymentMethod: 'Phương thức thanh toán',
  },
  bookerId: 'ID Booker',
  bankAccountsList: {
    branch: 'Chi nhánh',
    bankCode: 'Tên ngân hàng',
    bankAccountNo: 'Số tài khoản',
    bankAccountName: 'Chủ tài khoản',
    addBankAccount: 'Thêm tài khoản ngân hàng',
    editBankAccount: 'Sửa tài khoản ngân hàng',
    add: 'Thêm',
    edit: 'Sửa',
    cancel: 'Huỷ',
    ph: {
      branch: 'Nhập Chi nhánh',
      bankCode: 'Nhập tên ngân hàng',
      bankAccountNo: 'Nhập Số tài khoản',
      bankAccountName: 'Nhập Chủ tài khoản',
    },
    isDefault: 'Mặc định',
    action: 'Hành động',
  },
  employees: {
    title: 'Danh sách nhân viên của đại lý',
    columns: {
      no: 'STT',
      empCode: 'Mã nhân viên',
      empInfo: 'Thông tin nhân viên',
      tabInfo: 'Thông tin tài khoản',
      created: 'Thời gian tạo',
      updated: 'Thời gian sửa đổi',
      status: 'Trạng thái',
      action: 'Hành động',
    },
    changePassword: 'Đặt lại mật khẩu',
    changePasswordTitle: 'Điền vào mật khẩu mới',
    changePasswordSuccess: 'Đặt lại mật khẩu thành công!',
    changePasswordError: 'Đặt lại mật khẩu thất bại!',
    OnlyAgencyHighUp:
      'Chỉ đại lý cấp trên của đại lý hiện tại mới có thể thực hiện hành động này!',
    OnlyADMandOPEandSErole:
      'Chỉ Admin, OPE hoặc Sales có quyền thực hiện hành động này!',
    plsInputPassword: 'Vui lòng nhập vào mật khẩu mới!',
    min6character: 'Nhập vào ít nhất 6 ký tự',
  },
  note: 'Ghi chú',
  no: 'STT',
  auditLog: 'Lịch sử thay đổi',
  paxUpdated: 'Người thay đổi',
  updatedData: 'Thông tin sửa đổi',
  updatedAt: 'Thời gian sửa đổi',
  action: 'Hành động',
  auditLogsCredit: {
    no: 'STT',
    updated: 'Thời gian sửa đổi',
    limitCredit: 'Hạn mức tín dụng',
    warningCredit: 'Hạn mức cảnh báo',
    note: 'Ghi chú',
    outstandingBalance: 'Số dư nợ',
  },
  topupRecords: {
    createdAt: 'Ngày tạo',
    confirmedBy: 'Xác nhận bởi',
    balance: 'Công nợ',
    paymentAmount: 'Số tiền',
    note: 'Ghi chú',
  },

  editBalance: {
    title: 'Chỉnh sửa số dư nợ',
    balanceNow: 'Số dư nợ hiện tại',
    limitCredit: 'Hạn mức tín dụng tối đa',
    editBalanceForAgency: 'Chỉnh sửa số dư nợ đại lý',
    areYouConfirm: 'Bạn có chắc chắn muốn chỉnh sửa số dư nợ cho đại lý này ?',
    msgEditSuccess: 'Chỉnh sửa số dư nợ thành công',
    msgEditError: 'Lỗi chỉnh sửa số dư nợ',
  },

  fee: {
    titleModalEdit: 'Thay đổi phí xuất vé cho đại lý',
  },

  agenciesFeeOnly: 'Phí xuất vé riêng cho đại lý {floor}',
  confirmChange: 'Xác nhận thay đổi',

  modal: {
    toggleEnableSendEmployeeMailAirline:
      'Bạn có chắc chắn muốn thay đổi trạng thái cho phép nhân viên nhận mail hãng ?',
  },
  uploadContract: 'Tải lên hợp đồng',
  uploadImage: 'Tải ảnh lên',
  company: {
    title: 'Thông tin doanh nghiệp xuất hoá đơn',
    headerInfo: 'Thông tin công ty',
    create: 'Tạo',
    field: {
      name: 'Tên công ty',
      taxCode: 'Mã số thuế',
      province: 'Tỉnh/Thành phố',
      district: 'Quận/Huyện',
      ward: 'Phường/Xã',
      address: 'Địa chỉ',
      phoneNumber: 'Số điện thoại',
      email: 'Email',
      taxAuthorityCode: 'Mã cơ quan thuế',
      taxAuthorityName: 'Tên cơ quan thuế',
      agentName: 'Tên đại lý',
      agentPhoneNumber: 'Số điện thoại đại lý',
      active: 'Hoạt động',
      createdAt: 'Tạo lúc',
      updatedAt: 'Cập nhật lúc',
      generateInvoiceNumberOnCreation: 'Cấp số HĐ',
      onCreation: 'Khi tạo',
      onRelease: 'Khi phát hành',
    },
    ph: {
      name: 'Nhập Tên công ty',
      taxCode: 'Nhập Mã số thuế',
      province: 'Nhập Tỉnh/Thành phố',
      district: 'Nhập Quận/Huyện',
      ward: 'Nhập Phường/Xã',
      address: 'Nhập Địa chỉ',
      phoneNumber: 'Nhập Số điện thoại',
      email: 'Nhập Email',
      taxAuthorityCode: 'Nhập Mã cơ quan thuế',
      taxAuthorityName: 'Nhập Tên cơ quan thuế',
      agentName: 'Nhập Tên đại lý',
      agentPhoneNumber: 'Nhập Số điện thoại đại lý',
      createdAt: 'Tạo lúc',
      updatedAt: 'Cập nhật lúc',
    },
    noCompany:
      'Đại lý chưa có thông tin doanh nghiệp xuất hoá đơn. Vui lòng nhập thông tin và tạo.',
    activeCompany: 'Bật đăng kí',
    deactiveCompany: 'Tắt đăng kí',
  },
  webConfig: {
    noWebConfig: 'Đại lý chưa cấu hình web riêng. Vui lòng cấu hình.',
    title: 'Cấu hình web',
    smtpOptions: 'Cấu hình SMTP',
    field: {
      title: 'Tên trang',
      websiteUrl: 'Website URL',
      useSeparateATS: 'Sử dụng web riêng',
      email: 'Địa chỉ Email',
      configs: 'Cấu hình',
      logo: 'Logo',
      banner: 'Banner đăng nhập',
      bannerSearch: 'Banner màn hình tìm kiếm chuyến bay',
      emailType: 'Loại email',
      host: 'Host',
      port: 'Port',
      username: 'Username',
      password: 'Password',
      applyForEmployee: 'Gửi mail cho nhân viên',
      content: 'Nội dung',
      favicon: 'Favicon',
      logoNoti: 'Logo thông báo',
    },
    ph: {
      title: 'Title',
      websiteUrl: 'Nhập Website URL',
      logo: 'Nhập url hoặc tải file lên',
      banner: 'Nhập url hoặc tải file lên',
      emailType: 'Chọn loại email',
      email: 'Nhập địa chỉ Email',
      host: 'Nhập Host',
      port: 'Nhập Port',
      username: 'Nhập Username',
      password: 'Nhập Password',
      applyForEmployee: 'Gửi mail cho nhân viên',
      content: 'Nội dung',
    },
    emailType: {
      'Yahoo mail': 'Yahoo Mail',
      'Google mail': 'Google mail',
      Other: 'Khác',
    },
  },
}
