export default {
  source: 'Hệ thống',
  bookingCode: 'Mã đặt chỗ',
  status: 'Trạng thái',
  passenger: 'Hành khách',
  booker: 'Người đặt',
  flight: 'Chuyến bay hiện tại',
  createdAt: 'Ngày đặt',
  checkInOnline: 'Check-in online',
  action: 'Hành động',
  note: 'Ghi chú',
  hourPreparation: 'Giờ chuẩn bị',
  searchText: 'Tìm kiếm',
}
