export default {
  title: 'Thông tin tài khoản ngân hàng',
  addBankAccount: 'Thêm ngân hàng',
  editBankAccount: 'Sửa ngân hàng',
  verifyBankAccount: 'Xác thực ngân hàng',
  add: 'Thêm',
  edit: 'Sửa',
  cancel: 'Hủy',
  submit: 'Gửi',
  resend: 'Gửi lại mã xác thực',
  action: 'Hành động',
  bankId: 'Ngân hàng',
  branch: 'Chi nhánh',
  isDefault: 'Mặc định',
  isAuto: 'Giao dịch tự động',
  bankAccountNo: 'Số tài khoản',
  bankAccountName: 'Chủ tài khoản',
  identificationNumber: 'CCCD/CMT/Passport',
  phoneNumber: 'Số điện thoại',
  status: 'Trạng thái',
  label: 'Nhãn',
  otp: 'OTP',
  active: 'Hoạt động',
  unActive: 'Không hoạt động',
  ph: {
    bankId: 'Chọn ngân hàng',
    branch: 'Nhập chi nhánh',
    isDefault: 'Mặc định',
    isAuto: 'Giao dịch tự động',
    bankAccountNo: 'Nhập số tài khoản',
    bankAccountName: 'Nhập chủ tài khoản',
    identificationNumber: 'Nhập CCCD/CMT/Passport',
    phoneNumber: 'Nhập số điện thoại',
    label: 'Nhập nhãn',
    otp: 'Nhập OTP',
  },
  DELETING: 'Đang xóa',
  PENDING: 'Chờ xác thực',
  CONFIRMED: 'Đã xác thực',
}
